/*********************************************************************************

	Template Name: Template full name  
	Template URI: https://themeforest.net/user/
	Description: Template description
	Author: author name
	Author URI: author uri
	Version: 1.0

	Note: This is style css.

**********************************************************************************/
/*==============================
	CSS INDEX
===============================

	Reset
	Typography
	Helper Class
	Section Title
	Button
	Header Area
	Navigation Area
	Banner Area
	Footer Area
	Slider Area
	Service Area
	Product Area
	Testimonial Area
	Blog Area
	About Area
	Pizza Area
	Counterup Area
	Feature Area
	Team Area
	Contact Area
	Breadcamp Area
	Menu Grid Area
	Pagination Area
	Menu List
	Blog Masonary
	About Us
	Accordion Area
	Sidebar Area
	Blog Grid
	Blog Details
	Cart
	Menu Details
	Login
	CartBox
	Checkbox
	Responsive


================================*/
/*=========================================
	font-family: 'Open Sans', sans-serif;

	font-family: 'Alegreya', serif;
=======================================*/
/*Font Family*/
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,800");
@import url("https://fonts.googleapis.com/css?family=Alegreya:400,400i,500,500i,600,700i,700,900");
/*Basic styles*/
/*======= Reset =======*/
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: "Open Sans", sans-serif;
}

body {
  color: #141412;
  line-height: 1.5;
  margin: 0;
}

a {
  color: #444444;
  text-decoration: none;
}

a:focus {
  outline: thin dotted;
}

a:active,
a:hover {
  color: #ac0404;
  outline: 0;
}

a:hover {
  text-decoration: underline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  clear: both;
  font-family: "Alegreya", serif;
  line-height: 1.3;
}

h1 {
  font-size: 48px;
  margin: 33px 0;
}

h2 {
  font-size: 30px;
  margin: 25px 0;
}

h3 {
  font-size: 22px;
  margin: 22px 0;
}

h4 {
  font-size: 20px;
  margin: 25px 0;
}

h5 {
  font-size: 18px;
  margin: 30px 0;
}

h6 {
  font-size: 16px;
  margin: 36px 0;
}

address {
  font-style: italic;
  margin: 0 0 24px;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #d50c0d;
  color: #000;
}

p {
  margin: 0 0 24px;
}

code,
kbd,
pre,
samp {
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}

pre {
  background: #f5f5f5;
  color: #666;
  font-family: monospace;
  font-size: 14px;
  margin: 20px 0;
  overflow: auto;
  padding: 20px;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

blockquote,
q {
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

blockquote {
  font-size: 18px;
  font-style: italic;
  font-weight: 300;
  margin: 24px 40px;
}

blockquote blockquote {
  margin-right: 0;
}

blockquote cite,
blockquote small {
  font-size: 14px;
  font-weight: normal;
  text-transform: uppercase;
}

blockquote em,
blockquote i {
  font-style: normal;
  font-weight: 300;
}

blockquote strong,
blockquote b {
  font-weight: 400;
}

small {
  font-size: smaller;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

dl {
  margin: 0 20px;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 20px;
}

ul {
  list-style: none;
  padding: 0;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

li > ul,
li > ol {
  margin: 0;
}

img {
  -ms-interpolation-mode: bicubic;
  border: 0;
  vertical-align: middle;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  min-width: inherit;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  max-width: 100%;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

input[type="checkbox"],
input[type="radio"] {
  padding: 0;
}

input[type="search"] {
  -webkit-appearance: textfield;
  padding-right: 2px; /* Don't cut off the webkit search cancel button */
  width: 270px;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-bottom: 1px solid #ededed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
  line-height: 2;
  margin: 0 0 20px;
  width: 100%;
}

caption,
th,
td {
  font-weight: normal;
  text-align: left;
}

caption {
  font-size: 16px;
  margin: 20px 0;
}

th {
  font-weight: bold;
  text-transform: uppercase;
}

td {
  border-top: 1px solid #ededed;
  padding: 6px 10px 6px 0;
}

del {
  color: #333;
}

ins {
  background: #fff9c0;
  text-decoration: none;
}

hr {
  background-size: 4px 4px;
  border: 0;
  height: 1px;
  margin: 0 0 24px;
}

*::-moz-selection {
  background: #d50c0d;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #d50c0d;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #d50c0d;
  color: #fff;
  text-shadow: none;
}

/*======= Typography =======*/
html {
  overflow-x: hidden;
}

body {
  overflow-x: hidden;
  font-size: 14px;
  line-height: 24px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Open Sans", sans-serif;
  color: #959595;
  font-weight: 400;
}

::-webkit-input-placeholder {
  color: #959595;
}

:-moz-placeholder {
  color: #959595;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #959595;
  opacity: 1;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
address,
p,
pre,
blockquote,
dl,
dd,
menu,
ol,
ul,
table,
caption,
hr {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Alegreya", serif;
  line-height: 1.3;
}

p {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 24px;
}

h1,
.h1 {
  font-size: 48px;
}

h2,
.h2 {
  font-size: 30px;
}

h3,
.h3 {
  font-size: 22px;
}

h4,
.h4 {
  font-size: 20px;
}

h5,
.h5 {
  font-size: 18px;
}

h6,
.h6 {
  font-size: 16px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1,
  .h1 {
    font-size: 36px;
  }
  h2,
  .h2 {
    font-size: 26px;
  }
  h3,
  .h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 767px) {
  h1,
  .h1 {
    font-size: 32px;
  }
  h2,
  .h2 {
    font-size: 24px;
  }
  h3,
  .h3 {
    font-size: 18px;
  }
}
h1,
.h1,
h2,
.h2 {
  font-weight: 700;
}

h3,
.h3,
h4,
.h4 {
  font-weight: 600;
}

h5,
.h5,
h6,
.h6 {
  font-weight: 500;
}

a {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
  outline: none;
  color: #d50c0d;
}

.container {
  max-width: 1140px;
}

/*======= Helper Class =======*/
.hidden {
  display: none;
}

.poss--relative {
  position: relative;
}

.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

.invisible {
  visibility: hidden;
}

.clearfix:before,
.clearfix:after {
  content: " "; /* 1 */
  display: table; /* 2 */
}

.clearfix:after {
  clear: both;
}

a#scrollUp {
  background: #ffffff;
  color: #303030;
  bottom: 40px;
  right: 40px;
  border-radius: 100px;
  height: 45px;
  width: 45px;
  font-size: 25px;
  text-align: center;
  padding: 9px 0;
  -webkit-box-shadow: 0 0 15px 0px rgba(50, 50, 50, 0.2);
  box-shadow: 0 0 15px 0px rgba(50, 50, 50, 0.2);
}
a#scrollUp:hover {
  background: #d50c0d;
  color: #fff;
}

.color--theme {
  color: #d50c0d;
}

.color--light {
  color: #999999;
}

.color--dark {
  color: #333333;
}

.color--black {
  color: #000000;
}

.color--white {
  color: #ffffff;
}

.bg--theme {
  background: #d50c0d;
}

.bg--light {
  background: #999999;
}

.bg--dark {
  background: #333333;
}

.bg--black {
  background: #000000;
}

.bg--white {
  background: #ffffff;
}

.bg__cat--1 {
  background: #222222;
}

.bg__cat--2 {
  background: #212121;
}

.bg__cat--3 {
  background: #f9f9f9;
}

.bg__cat--4 {
  background: #fafafa;
}

.bg__cat--5 {
  background: #ffcb01;
}

.bg__cat--6 {
  background: #282c35;
}

.bg__cat--7 {
  background: #ededed;
}

.bg-image--18 {
  background-image: url(images/bg/18.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}

/*Separator*/
/*Margin*/
.mt--30 {
  margin-top: 30px;
}

.mt--40 {
  margin-top: 40px;
}

.mt--50 {
  margin-top: 50px;
}

.mt--60 {
  margin-top: 60px;
}

.mt--70 {
  margin-top: 70px;
}

.mt--80 {
  margin-top: 80px;
}

.mt--90 {
  margin-top: 90px;
}

.mt--100 {
  margin-top: 100px;
}

/*Padding Ptb*/
.ptb--50 {
  padding: 50px 0;
}

.ptb--60 {
  padding: 60px 0;
}

.ptb--70 {
  padding: 70px 0;
}

.ptb--80 {
  padding: 80px 0;
}

.ptb--90 {
  padding: 90px 0;
}

.ptb--100 {
  padding: 100px 0;
}

.ptb--110 {
  padding: 110px 0;
}

.ptb--120 {
  padding: 120px 0;
}

.ptb--130 {
  padding: 130px 0;
}

.ptb--140 {
  padding: 140px 0;
}

.ptb--150 {
  padding: 150px 0;
}

.ptb--160 {
  padding: 160px 0;
}

.ptb--170 {
  padding: 170px 0;
}

/*Padding Pb*/
.pb--50 {
  padding-bottom: 50px;
}

.pb--60 {
  padding-bottom: 60px;
}

.pb--70 {
  padding-bottom: 70px;
}

.pb--80 {
  padding-bottom: 80px;
}

.pb--90 {
  padding-bottom: 90px;
}

.pb--100 {
  padding-bottom: 100px;
}

.pb--110 {
  padding-bottom: 110px;
}

.pb--120 {
  padding-bottom: 120px;
}

.pb--130 {
  padding-bottom: 130px;
}

/*Padding P7*/
.pt--50 {
  padding-top: 50px;
}

.pt--60 {
  padding-top: 60px;
}

.pt--70 {
  padding-top: 70px;
}

.pt--80 {
  padding-top: 80px;
}

.pt--90 {
  padding-top: 90px;
}

.pt--100 {
  padding-top: 100px;
}

.pt--110 {
  padding-top: 110px;
}

.pt--120 {
  padding-top: 120px;
}

.pt--130 {
  padding-top: 130px;
}

.pt--140 {
  padding-top: 140px;
}

.pt--150 {
  padding-top: 150px;
}

.pt--160 {
  padding-top: 160px;
}

.pt--170 {
  padding-top: 170px;
}

.pt--180 {
  padding-top: 180px;
}

.pt--190 {
  padding-top: 190px;
}

.pt--200 {
  padding-top: 200px;
}

.pt--210 {
  padding-top: 210px;
}

.pt--220 {
  padding-top: 220px;
}

.pt--230 {
  padding-top: 230px;
}

.pt--240 {
  padding-top: 240px;
}

.pt--250 {
  padding-top: 250px;
}

.pt--260 {
  padding-top: 260px;
}

.pt--270 {
  padding-top: 270px;
}

.pt--280 {
  padding-top: 280px;
}

.pt--290 {
  padding-top: 290px;
}

.pt--300 {
  padding-top: 300px;
}

.pt--310 {
  padding-top: 310px;
}

.pt--320 {
  padding-top: 320px;
}

.pt--330 {
  padding-top: 330px;
}

.pt--340 {
  padding-top: 340px;
}

.pt--350 {
  padding-top: 350px;
}

.pt--360 {
  padding-top: 360px;
}

.pt--370 {
  padding-top: 370px;
}

.pt--380 {
  padding-top: 380px;
}

.pt--390 {
  padding-top: 390px;
}

.pt--400 {
  padding-top: 400px;
}

.pt--410 {
  padding-top: 410px;
}

.pt--420 {
  padding-top: 420px;
}

.pt--430 {
  padding-top: 430px;
}

.pt--440 {
  padding-top: 440px;
}

.pt--450 {
  padding-top: 450px;
}

.pt--460 {
  padding-top: 460px;
}

.pt--470 {
  padding-top: 470px;
}

.pt--480 {
  padding-top: 480px;
}

.pt--490 {
  padding-top: 490px;
}

.pt--500 {
  padding-top: 500px;
}

.pt--510 {
  padding-top: 510px;
}

.pt--520 {
  padding-top: 520px;
}

.pt--530 {
  padding-top: 530px;
}

.pt--540 {
  padding-top: 540px;
}

.pt--550 {
  padding-top: 550px;
}

.pt--560 {
  padding-top: 560px;
}

.pt--570 {
  padding-top: 570px;
}

.pt--580 {
  padding-top: 580px;
}

.pt--590 {
  padding-top: 590px;
}

.pt--600 {
  padding-top: 600px;
}

.pt--610 {
  padding-top: 610px;
}

.pt--620 {
  padding-top: 620px;
}

.pt--630 {
  padding-top: 630px;
}

.pt--640 {
  padding-top: 640px;
}

.pt--650 {
  padding-top: 650px;
}

.pt--660 {
  padding-top: 660px;
}

.pt--670 {
  padding-top: 670px;
}

.pt--680 {
  padding-top: 680px;
}

.pt--690 {
  padding-top: 690px;
}

.pt--700 {
  padding-top: 700px;
}

.pt--710 {
  padding-top: 710px;
}

.pt--720 {
  padding-top: 720px;
}

.pt--730 {
  padding-top: 730px;
}

.pt--740 {
  padding-top: 740px;
}

.pt--750 {
  padding-top: 750px;
}

.pt--760 {
  padding-top: 760px;
}

.pt--770 {
  padding-top: 770px;
}

.pt--780 {
  padding-top: 780px;
}

.pt--790 {
  padding-top: 790px;
}

.pt--800 {
  padding-top: 800px;
}

.pt--810 {
  padding-top: 810px;
}

.pt--820 {
  padding-top: 820px;
}

.pt--830 {
  padding-top: 830px;
}

.pt--840 {
  padding-top: 840px;
}

.pt--850 {
  padding-top: 850px;
}

.pt--860 {
  padding-top: 860px;
}

.pt--870 {
  padding-top: 870px;
}

.pt--880 {
  padding-top: 880px;
}

.pt--890 {
  padding-top: 890px;
}

.pt--900 {
  padding-top: 900px;
}

.pt--910 {
  padding-top: 910px;
}

.pt--920 {
  padding-top: 920px;
}

.pt--930 {
  padding-top: 930px;
}

.pt--940 {
  padding-top: 940px;
}

.pt--950 {
  padding-top: 950px;
}

.pt--960 {
  padding-top: 960px;
}

.pt--970 {
  padding-top: 970px;
}

.pt--980 {
  padding-top: 980px;
}

.pt--990 {
  padding-top: 990px;
}

.pt--1000 {
  padding-top: 1000px;
}

/*Margin Bottom*/
.mb--50 {
  margin-bottom: 50px;
}

.mb--60 {
  margin-bottom: 60px;
}

.mb--70 {
  margin-bottom: 70px;
}

.mb--80 {
  margin-bottom: 80px;
}

.mb--90 {
  margin-bottom: 90px;
}

.mb--100 {
  margin-bottom: 100px;
}

/*Margin Bottom*/
.mt--50 {
  margin-top: 50px;
}

.mt--60 {
  margin-top: 60px;
}

.mt--70 {
  margin-top: 70px;
}

.mt--80 {
  margin-top: 80px;
}

.mt--90 {
  margin-top: 90px;
}

.mt--100 {
  margin-top: 100px;
}

.mt--110 {
  margin-top: 110px;
}

.mt--120 {
  margin-top: 120px;
}

.mt--130 {
  margin-top: 130px;
}

.plr--250px {
  padding: 0 250px 0 250px;
}

.ptb--250px {
  padding: 260px 0;
}

.fw--900 {
  font-weight: 900;
}

.fw--700 {
  font-weight: 700;
}

.fw--600 {
  font-weight: 600;
}

.fw--500 {
  font-weight: 500;
}

.fw--400 {
  font-weight: 400;
}

.fw--300 {
  font-weight: 300;
}

.text--italic {
  font-style: italic;
}

.text--normal {
  font-style: normal;
}

.text--underline {
  font-style: underline;
}

.body--font {
  font-family: "Open Sans", sans-serif;
}

.heading--font {
  font-family: "Alegreya", serif;
}

.fullscreen {
  min-height: 100vh;
  width: 100%;
}

.w--100 {
  width: 100%;
}

.w--75 {
  width: 75%;
}

.w--50 {
  width: 50%;
}

.w--33 {
  width: 33.33%;
}

.w--25 {
  width: 25%;
}

.w--10 {
  width: 10%;
}

.h--100 {
  height: 100%;
}

.h--75 {
  height: 75%;
}

.h--50 {
  height: 50%;
}

.h--25 {
  height: 25%;
}

.h--33 {
  height: 33.33%;
}

.h--10 {
  height: 10%;
}

/* Section padding */
.section-padding--xlg {
  padding: 200px 0;
}

.section-padding--lg {
  padding: 130px 0;
}

.section-pt--lg {
  padding-top: 130px;
}

.section-padding--md {
  padding: 120px 0;
}

.section-padding--sm {
  padding: 100px 0;
}

.section-padding--xs {
  padding: 80px 0;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-padding--xlg {
    padding: 120px 0;
  }
  .section-padding--lg {
    padding: 100px 0;
  }
  .section-padding--md {
    padding: 85px 0;
  }
  .section-padding--sm {
    padding: 70px 0;
  }
  .section-padding--xs {
    padding: 60px 0;
  }
  .md--mt--40 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-padding--xlg {
    padding: 90px 0;
  }
  .section-padding--lg {
    padding: 70px 0;
  }
  .section-padding--md {
    padding: 60px 0;
  }
  .section-padding--sm {
    padding: 50px 0;
  }
  .section-padding--xs {
    padding: 50px 0;
  }
  .sm--mt--40 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .xs--mt--40 {
    margin-top: 40px;
  }
}
/* Overlay styles */
[data-overlay],
[data-black-overlay] {
  position: relative;
}

[data-overlay] > div,
[data-overlay] > *,
[data-black-overlay] > div,
[data-black-overlay] > * {
  position: relative;
  z-index: 2;
}

[data-overlay]:before,
[data-black-overlay]:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
}

[data-overlay]:before {
  background-color: #d50c0d;
}

[data-black-overlay]:before {
  background-color: #000000;
}

[data-overlay="1"]:before,
[data-black-overlay="1"]:before {
  opacity: 0.1;
}

[data-overlay="2"]:before,
[data-black-overlay="2"]:before {
  opacity: 0.2;
}

[data-overlay="3"]:before,
[data-black-overlay="3"]:before {
  opacity: 0.3;
}

[data-overlay="4"]:before,
[data-black-overlay="4"]:before {
  opacity: 0.4;
}

[data-overlay="5"]:before,
[data-black-overlay="5"]:before {
  opacity: 0.5;
}

[data-overlay="6"]:before,
[data-black-overlay="6"]:before {
  opacity: 0.6;
}

[data-overlay="7"]:before,
[data-black-overlay="7"]:before {
  opacity: 0.7;
}

[data-overlay="8"]:before,
[data-black-overlay="8"]:before {
  opacity: 0.8;
}

[data-overlay="9"]:before,
[data-black-overlay="9"]:before {
  opacity: 0.9;
}

[data-overlay="10"]:before,
[data-black-overlay="10"]:before {
  opacity: 1;
}

/*Elements*/
/*======= Section Title =======*/
.box__layout {
  margin-left: 375px;
  margin-right: 375px;
}

.section__title {
  padding-top: 17px;
  position: relative;
}
.section__title.title__style--2 {
  padding-bottom: 18px;
  padding-top: 0;
  position: relative;
  padding-bottom: 18px;
  padding-top: 24px;
  position: relative;
}
.section__title.title__style--2::after {
  background-image: url("images/icon/shape/4.png");
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  height: 7px;
  left: 50%;
  position: absolute;
  top: auto;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 200px;
}
.section__title.title__style--2 .title__line {
  padding-top: 0px;
  margin-top: 0;
}
.section__title.title__style--2 p {
  margin-top: 17px;
}
.section__title.service__align--left {
  text-align: left;
}
.section__title.service__align--center {
  text-align: center;
}
.section__title.service__align--right {
  text-align: right;
}
.section__title::before {
  background-image: url("images/icon/shape/1.png");
  background-repeat: no-repeat;
  content: "";
  height: 14px;
  left: 0;
  position: absolute;
  top: 0;
  width: 45px;
}
.section__title.service__align--center::before {
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.section__title.service__align--right::before {
  left: auto;
  right: 0;
}
.section__title.section__bg__black .title__line {
  color: #ffffff;
}
.section__title.section__bg__black p {
  color: #ffffff;
}
.section__title.section__bg__black::before {
  background-image: url("images/icon/shape/5.png");
}
.section__title p {
  color: #444444;
  font-size: 16px;
}
.section__title .title__line {
  color: #444444;
  font-size: 50px;
  font-weight: 400;
  line-height: 42px;
  margin-top: 10px;
}

@media only screen and (max-width: 767px) {
  .section__title .title__line {
    font-size: 34px;
    line-height: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .section__title .title__line {
    font-size: 28px;
  }
}
/*======= Button Style =======*/
.food__btn.white--btn {
  background: #fff none repeat scroll 0 0;
  color: #444444;
}

.food__btn {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  padding: 0 22px;
  text-transform: capitalize;
  position: relative;
  -webkit-transform: translateZ(0px);
  transform: translateZ(0px);
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
}
.food__btn.white--btn {
  background: #fff none repeat scroll 0 0;
  color: #444444;
}
.food__btn.white--btn:hover {
  color: #fff;
}
.food__btn.black--button {
  background: #262626 none repeat scroll 0 0;
  color: #fff !important;
}
.food__btn::before {
  background: #222222 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: 0 50% 0;
  transform-origin: 0 50% 0;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  z-index: -1;
  border-radius: 5px;
}
.food__btn.theme--hover::before {
  background: #d50c0d none repeat scroll 0 0;
}

.food__btn.grey--btn {
  background: #f2f2f2 none repeat scroll 0 0;
  color: #444444;
  font-size: 16px;
  font-weight: 700;
}

.food__btn.grey--btn.mid-height {
  height: 36px;
  line-height: 36px;
  padding: 0 24px;
}

.food__btn.grey--btn:hover {
  color: #fff;
}

.food__btn:active::before,
.food__btn:focus::before,
.food__btn:hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.food__btn:active,
.food__btn:focus,
.food__btn:hover {
  color: #fff;
}

.food__btn.btn--green {
  background: #60ba62;
}

.fd-btn.food__btn {
  border: 0 none;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}

.pizza:hover .food__btn.theme--hover::before {
  background: #d50c0d none repeat scroll 0 0;
}

.pizza:active .food__btn.theme--hover::before,
.pizza:focus .food__btn.theme--hover::before,
.pizza:hover .food__btn.theme--hover::before {
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
  -webkit-transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
  transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
}

.pizza:active .food__btn.theme--hover,
.pizza:focus .food__btn.theme--hover,
.pizza:hover .food__btn.theme--hover {
  color: #fff;
}

.slider__btn .food__btn {
  background: #d50c0d none repeat scroll 0 0;
  font-size: 26px;
  font-weight: 700;
  height: 50px;
  line-height: 46px;
  padding: 0 36px;
}

.food__btn.btn__hover--theme::before {
  background: #d50c0d none repeat scroll 0 0;
}

.food__btn.btn--transparent:hover {
  color: #fff;
}

.subscribe--3 .food__btn {
  background: #d50c0d none repeat scroll 0 0;
}

.subscribe--3 .food__btn::before {
  background: #222222 none repeat scroll 0 0;
}

.grid__show__btn .food__btn {
  border-radius: 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  height: 35px;
  letter-spacing: 2px;
  line-height: 35px;
  padding: 0 17px;
  position: relative;
}

.grid__show__btn .food__btn::before {
  border-radius: 0;
}

.video-play-button,
a.video-play-button,
button.video-play-button {
  display: inline-block;
  height: 55px;
  width: 55px;
  background: #fff;
  line-height: 55px;
  text-align: center;
  position: relative;
  border-radius: 100%;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.video-play-button img,
a.video-play-button img,
button.video-play-button img {
  padding-left: 5px;
  position: relative;
}
.video-play-button:before,
.video-play-button:after,
a.video-play-button:before,
a.video-play-button:after,
button.video-play-button:before,
button.video-play-button:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: transparent;
  border: 1px solid #ffffff;
  z-index: -1;
  border-radius: 100%;
  opacity: 0;
}
.video-play-button:before,
a.video-play-button:before,
button.video-play-button:before {
  z-index: 2;
  -webkit-animation: playbutton-effect 1s ease-in-out infinite;
  animation: playbutton-effect 1s ease-in-out infinite;
}
.video-play-button:after,
a.video-play-button:after,
button.video-play-button:after {
  z-index: 2;
  -webkit-animation: playbutton-effect 1s ease-in-out infinite;
  animation: playbutton-effect 1s ease-in-out infinite;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}
.video-play-button:hover,
a.video-play-button:hover,
button.video-play-button:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

@-webkit-keyframes playbutton-effect {
  from {
    opacity: 1;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2.3);
    transform: scale(2.3);
  }
}
@keyframes playbutton-effect {
  from {
    opacity: 1;
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  to {
    opacity: 0;
    -webkit-transform: scale(2.3);
    transform: scale(2.3);
  }
}
.video a.video-play-button {
  margin-bottom: 40px;
}
.video h2 {
  color: #ffffff;
  font-size: 46px;
}
.video h4 {
  color: #ffffff;
  font-size: 30px;
  line-height: 40px;
  letter-spacing: 0.5px;
  margin-bottom: 0;
}

.yu2fvl {
  z-index: 9999;
  top: 0;
}

.yu2fvl-iframe {
  display: block;
  height: 100%;
  width: 100%;
  border: 0;
}

.yu2fvl-overlay {
  z-index: 9998;
  background: #000;
  opacity: 0.8;
}

.yu2fvl-close {
  position: absolute;
  top: 1px;
  left: 100%;
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  background: #f10 !important;
  background-color: #f10 !important;
  color: #ffffff;
  border: 0;
  background: none;
  cursor: pointer;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .yu2fvl-close {
    left: auto;
    right: 0;
    top: auto;
    bottom: 100%;
  }
  .video h2 {
    color: #ffffff;
    font-size: 32px;
  }
  .video h4 {
    font-size: 22px;
    line-height: 32px;
  }
}
/*Blocks*/
/*======= Header Styles =======*/
.htc__header {
  position: relative;
  z-index: 99;
}

.mainmenu__wrap .logo a {
  height: 100px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.log__in div,
.shopping__cart a {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100px;
}

.header__right .log__in div i {
  color: #666666;
  font-size: 20px;
}
.header__right .shopping__cart {
  margin-left: 13px;
  padding-left: 13px;
  padding-right: 12px;
  position: relative;
}
.header__right .shopping__cart::before {
  background: #d50c0d;
  content: "";
  height: 12px;
  left: 0;
  position: absolute;
  top: 45%;
  width: 1px;
}
.header__right .shopping__cart a i {
  color: #666666;
  font-size: 20px;
}
.header__right .shopping__cart .shop__qun {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  height: 22px;
  line-height: 19.5px;
  position: absolute;
  right: 2px;
  text-align: center;
  top: 23%;
  width: 22px;
}
.header__right .shopping__cart .shop__qun span {
  font-size: 12px;
  font-weight: 700;
}

.sticky__header.is-sticky {
  -webkit-animation: 0.4s ease-in-out 0s normal both 1 running fadeInDown;
  animation: 0.4s ease-in-out 0s normal both 1 running fadeInDown;
  -webkit-box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
  left: 0;
  position: fixed;
  top: 0;
  -webkit-transition: -webkit-box-shadow 0.5s ease-in-out 0s;
  transition: -webkit-box-shadow 0.5s ease-in-out 0s;
  transition: box-shadow 0.5s ease-in-out 0s;
  transition:
    box-shadow 0.5s ease-in-out 0s,
    -webkit-box-shadow 0.5s ease-in-out 0s;
  width: 100%;
  z-index: 998;
  background: #fff;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .header__right {
    position: relative;
    z-index: 2;
  }
  .logo {
    position: relative;
    z-index: 99;
  }
}
@media only screen and (max-width: 767px) {
  .header__right {
    position: relative;
    z-index: 2;
  }
  .logo {
    position: relative;
    z-index: 99;
  }
}
@media only screen and (max-width: 575px) {
  .header__right {
    position: relative;
    z-index: 2;
  }
  .logo {
    position: relative;
    z-index: 99;
  }
}
/*======= Navigation Styles =======*/
nav.main__menu__nav {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  justify-content: center;
}
nav.main__menu__nav ul {
  padding-left: 0;
  margin-bottom: 0;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  justify-content: center;
}
nav.main__menu__nav ul li {
  padding: 0 20px;
  display: inline-block;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
}
nav.main__menu__nav ul li:hover a {
  color: #d50c0d;
}
nav.main__menu__nav ul li.drop {
  position: relative;
}
nav.main__menu__nav ul li.drop:hover ul.dropdown__menu {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
nav.main__menu__nav ul li.drop ul.dropdown__menu {
  background: #fff;
  border: medium none;
  border-radius: 0;
  display: block;
  left: -50px;
  min-width: 232px;
  opacity: 0;
  padding: 0;
  position: absolute;
  text-align: left;
  top: 100%;
  -webkit-transform: translateY(10%);
  transform: translateY(10%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  visibility: hidden;
}
nav.main__menu__nav ul li.drop ul.dropdown__menu li {
  border-bottom: 1px solid #f2f2f2;
  display: block;
  float: none;
  margin-left: 0;
  margin-right: 0;
}
nav.main__menu__nav ul li.drop ul.dropdown__menu li a {
  color: #444;
  display: block;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  height: auto;
  line-height: 45px;
  padding: 0;
  text-transform: none;
  -webkit-transition: all 150ms linear 0s;
  transition: all 150ms linear 0s;
}
nav.main__menu__nav ul li.drop ul.dropdown__menu li a:hover {
  color: #d50c0d;
  padding-left: 5px;
}
nav.main__menu__nav ul li a {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-self: stretch;
  -ms-flex-item-align: stretch;
  -ms-grid-row-align: stretch;
  align-self: stretch;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  color: #444;
  font-size: 14px;
  height: 100px;
  font-weight: 700;
  text-transform: capitalize;
}

/*------------------------
	Subscribe Area
--------------------------*/
.subscribe__inner {
  padding: 100px 200px;
  text-align: center;
}
.subscribe__inner h2 {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 43px;
  text-transform: uppercase;
}
.subscribe__inner .enter__email__address {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
}

.subscribe__btn {
  margin-left: 10px;
}

.htc__news__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.news__input {
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
}

.news__input input {
  background: #222 none repeat scroll 0 0;
  border: 0 none;
  border-radius: 5px;
  color: #959595;
  height: 50px;
  padding: 0 30px;
  width: 100%;
  font-size: 14px;
  outline: medium none;
}

.subscribe__btn {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}

.subscribe__btn {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}

.subscribe__inner .enter__email__address .sign__up {
  border: 0 none;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  padding: 0 40px;
}

.sign__up.food__btn {
  border: 0 none;
  font-family: Open Sans;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  padding: 0 34px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sign__up.food__btn:hover {
  background: #222;
}

.subscribe__inner.subscribe--3 h2 {
  color: #ffffff;
  font-size: 24px;
  text-transform: uppercase;
}

.subscribe--2 .news__input input {
  background: #fff none repeat scroll 0 0;
  border: 0 none;
  border-radius: 0;
  -webkit-box-shadow: 0 0 32px rgba(49, 49, 49, 0.12);
  box-shadow: 0 0 32px rgba(49, 49, 49, 0.12);
}

.subscribe--2 .food__btn {
  background: #222222 none repeat scroll 0 0;
  border-radius: 0;
  color: #fff;
  text-transform: lowercase;
}

.subscribe--2 .food__btn:hover {
  background: #d50c0d;
}

.subscribe--2 .food__btn::before {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 0px;
}

.subscribe__inner.subscribe--3 .news__input input {
  background: #fff none repeat scroll 0 0;
}

.subscribe__inner .enter__email__address input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #959595;
}

.subscribe__inner .enter__email__address input:-ms-input-placeholder {
  /* IE 10+ */
  color: #959595;
}

.subscribe__inner .enter__email__address input::-moz-placeholder {
  color: #959595;
  opacity: 1;
}

.subscribe__inner .enter__email__address input::-ms-input-placeholder {
  color: #959595;
  opacity: 1;
}

.subscribe__inner .enter__email__address input::placeholder {
  color: #959595;
  opacity: 1;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe__inner {
    padding: 100px 75px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .subscribe__inner .enter__email__address input {
    -ms-flex-preferred-size: 76.5%;
    flex-basis: 76.5%;
  }
  .subscribe__inner {
    padding: 100px 30px;
  }
}
@media only screen and (max-width: 767px) {
  .subscribe__inner {
    padding: 100px 0;
  }
  .subscribe__inner .enter__email__address input {
    -ms-flex-preferred-size: 71.5%;
    flex-basis: 71.5%;
  }
}
@media only screen and (max-width: 575px) {
  .subscribe__inner {
    padding: 100px 0;
  }
  .subscribe__inner .enter__email__address input {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .subscribe__inner .enter__email__address .sign__up {
    margin-top: 20px;
  }
  .subscribe__inner h2 {
    font-size: 21px;
    margin-bottom: 30px;
  }
}
.fd__subscribe__wrapper .fd__subscribe__area {
  overflow: visible;
}

.subscribe__inner.subscribe--3 {
  background: #282c35 none repeat scroll 0 0;
  padding: 100px 200px;
  position: relative;
  text-align: center;
  top: -66px;
  padding-bottom: 30px;
  padding-top: 84px;
  z-index: 2;
}

.subscri__shape--1 {
  bottom: -12px;
  left: -73px;
  position: absolute;
}

.subscri__shape--2 {
  bottom: -32%;
  position: absolute;
  right: -7%;
}

.fd__subscribe__area {
  overflow: hidden;
}

.subscribe--2 .subscribe__inner .enter__email__address input {
  background: #fff none repeat scroll 0 0;
  color: #444444;
}

.subscribe__inner.subscribe--3 .enter__email__address input {
  -ms-flex-preferred-size: 80.5%;
  flex-basis: 80.5%;
}

.subs__address__content {
  margin-top: 50px;
}
.subs__address__content .subs__address {
  text-align: left;
}
.subs__address__content .subs__address .sbs__address__icon {
  padding-right: 20px;
}
.subs__address__content .subs__address .sbs__address__icon i {
  color: #ffffff;
  font-size: 36px;
}
.subs__address__content .subs__address .subs__address__details p {
  color: #ffffff;
  font-size: 14px;
}
.subs__address__content .subs__address .subs__address__details p a {
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.subs__address__content .subs__address .subs__address__details p a:hover {
  color: #d50c0d;
}

.subs--4 .fd__subscribe__area {
  padding: 0 100px;
}

.subs--4 .subscribe__inner.subscribe--3 {
  padding: 84px 100px 30px;
}

.subs--4 .subscribe__inner .enter__email__address input {
  height: 60px;
}

.subs--4 .subscribe__inner .enter__email__address .sign__up {
  height: 60px;
  padding: 0 35px;
  text-transform: uppercase;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .subscribe__inner.subscribe--3 {
    padding: 84px 100px 30px;
  }
}
/*------------------------------
	Banner Area
--------------------------------*/
.banner {
  position: relative;
}
.banner:hover a {
  opacity: 1;
}
.banner::before {
  background: rgba(213, 12, 13, 0.6) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
}
.banner::after {
  background: rgba(213, 12, 13, 0.6) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}
.banner a {
  left: 50%;
  opacity: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 1;
}
.banner a i {
  color: #fff;
  font-size: 60px;
}

/*------------------------------
	Banner Style Two
--------------------------------*/
.banner--2 {
  margin-top: 40px;
  position: relative;
}
.banner--2 .banner__thumb a {
  display: block;
}
.banner--2 .banner__thumb a img {
  width: 100%;
}
.banner--2 .banner__hover__action {
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  z-index: 9;
}
.banner--2 .banner__hover__action.banner__left__bottom {
  bottom: 30px;
  left: 30px;
  text-align: left;
  top: auto;
  -webkit-transform: translate(0px);
  transform: translate(0px);
  width: auto;
}
.banner--2 .banner__hover__action.banner__left__top {
  left: 30px;
  text-align: left;
  top: 30px;
  -webkit-transform: translate(0px);
  transform: translate(0px);
  width: auto;
}
.banner--2 .banner__hover__action.banner__right__bottom {
  bottom: 30px;
  left: auto;
  right: 30px;
  top: auto;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  width: auto;
}
.banner--2 .banner__hover__action .banner__hover__inner h4 {
  color: #fc892c;
  font-size: 18px;
  font-style: italic;
  font-weight: 400;
  line-height: 18px;
  text-transform: uppercase;
}
.banner--2 .banner__hover__action .banner__hover__inner h2 {
  color: #713311;
  font-size: 26px;
  font-weight: 900;
  text-transform: capitalize;
}
.banner--2 .banner__hover__action .banner__hover__inner h2.pink-text {
  color: #e61853;
  font-size: 36px;
  text-transform: uppercase;
}
.banner--2 .banner__hover__action .banner__hover__inner p {
  color: #444444;
  font-family: "Alegreya", serif;
  font-size: 14px;
  font-weight: 700;
  margin-top: 3px;
}
.banner--2 .banner__hover__action .banner__hover__inner span {
  color: #444444;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-size: 40px;
  font-weight: 700;
}

.banner__hover__inner h2 .banner__left__top p {
  font-size: 18px;
  font-weight: 400;
}

.banner__right__bottom p {
  font-size: 18px;
  font-weight: 400;
  margin-top: 7px;
}

.banner--2 {
  position: relative;
  z-index: 3;
}

.banner__thumb a {
  position: relative;
  z-index: 1;
}

.banner__thumb a::before,
.banner__thumb a::after {
  background: rgba(255, 255, 255, 0.4) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0 0;
  transform-origin: 0 0 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
}

.banner__thumb a::after {
  -webkit-transform-origin: 100% 100% 0;
  transform-origin: 100% 100% 0;
}

.banner--2:hover .banner__thumb a::before,
.banner--2:hover .banner__thumb a::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/*======= Footer Styles =======*/
/*----------------------
	Footer Area
------------------------*/
.footer.gallery {
  padding-left: 45px;
  padding-right: 45px;
}
.footer .ftr__title {
  color: #e4e5e9;
  font-size: 26px;
  font-weight: 400;
  line-height: 24px;
  padding-bottom: 24px;
  position: relative;
}
.footer .ftr__title::after {
  background-image: url("images/icon/shape/3.png");
  background-repeat: no-repeat;
  background-size: auto auto;
  bottom: 0;
  content: "";
  height: 8px;
  left: 0;
  position: absolute;
  width: 47px;
}
.footer .footer__inner {
  margin-top: 57px;
}
.copyright .copyright__inner .cpy__right--left p {
  color: #333333;
  font-size: 14px;
}

.copyright.bg__cat--7 .copyright__inner .cpy__right--left p a {
  color: #333333;
}

.copyright .copyright__inner .cpy__right--left p a {
  color: #fff;
  font-size: 14px;
}

.footer--2 .copyright .copyright__inner .cpy__right--left p {
  color: #333333;
}

.ftr__address {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.sm__gallery__list {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -2.5px;
  margin-right: -2.5px;
}
.sm__gallery__list li {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  margin-bottom: 5px;
  padding-left: 2.5px;
  padding-right: 2.5px;
}
.sm__gallery__list a {
  display: block;
  position: relative;
}
.sm__gallery__list a::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.5;
  position: absolute;
  top: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
}
.sm__gallery__list a:hover::before {
  opacity: 0;
}
.sm__gallery__list a img {
  width: 100%;
}

.ftr__details p {
  color: #e4e5e9;
  font-size: 14px;
  line-height: 24px;
}

.ftr__address__icon {
  padding-right: 14px;
}
.ftr__address__icon i {
  color: #ffffff;
  font-size: 20px;
}

.frt__address__details p {
  color: #ffffff;
}
.frt__address__details p a {
  color: #ffffff;
  font-size: 14px;
}

.ftr__address__inner .ftr__address + .ftr__address {
  margin-top: 13px;
}

.ftr__address__inner {
  margin-top: 18px;
}

.social__icon li {
  display: inline-block;
}
.social__icon li + li {
  margin-left: 10px;
}
.social__icon li a i {
  border: 1px solid #8a8a8a;
  color: #ffffff;
  display: block;
  font-size: 15px;
  line-height: 30px;
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 30px;
}
.social__icon li a i:hover {
  background: #d50c0d none repeat scroll 0 0;
  border: 1px solid #d50c0d;
  color: #fff;
}

.ftr__address__inner {
  margin-bottom: 32px;
}

.opening__time__list li {
  color: #ffffff;
  font-weight: 300;
}

.opening__time__list li + li {
  padding-top: 8px;
}

.single__sm__post {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.single__sm__post .sin__post__thumb {
  min-width: 70px;
}
.single__sm__post .sin__post__details {
  padding-left: 20px;
}
.single__sm__post .sin__post__details h6 a {
  color: #e4e5e9;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
}
.single__sm__post .sin__post__details p {
  color: #e4e5e9;
  font-size: 14px;
  line-height: 24px;
}

.lst__post__list .single__sm__post + .single__sm__post {
  margin-top: 20px;
}

.opening__time__list li span {
  padding: 0 22px;
}

.copyright .copyright__inner {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.copyright .copyright__inner .cpy__right--left p {
  color: #ffffff;
  font-size: 14px;
}

.footer--4 .copyright .copyright__inner {
  height: 82px;
  padding: 0 85px;
}

.footer--4 .copyright .copyright__inner .cpy__right--left p {
  color: #666666;
  font-size: 14px;
}

.single__sm__post .sin__post__details h6 a:hover,
.frt__address__details p a:hover {
  color: #d50c0d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer .ftr__title {
    font-size: 20px;
  }
  .footer.gallery {
    padding-left: 0;
    padding-right: 0;
  }
  .single__sm__post .sin__post__details {
    padding-left: 10px;
  }
  .single__sm__post .sin__post__details h6 a {
    font-size: 13px;
  }
  .single__sm__post .sin__post__details p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 767px) {
  .footer.gallery {
    padding-left: 0;
    padding-right: 0;
  }
  .sm__gallery__list li {
    -ms-flex-preferred-size: 33.33%;
    flex-basis: 33.33%;
  }
  .single__sm__post {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    -moz-justify-content: flex-start;
    justify-content: flex-start;
  }
  .copyright .copyright__inner .cpy__right--left p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 575px) {
  .copyright .copyright__inner {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    height: 100px;
  }
}
/*======= Service Styles =======*/
/*--------------------
	Slider One
---------------------*/
.slide {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  justify-content: center;
}
.slide .slider__content {
  position: relative;
  z-index: 3;
}
.slide .slider__content .slider__inner {
  text-align: center;
}
.slide .slider__content .slider__inner h2 {
  margin-bottom: 16px;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 50px;
}
.slide .slider__content .slider__inner h1 {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 50px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 50px;
}
.slide .slider__content .slider__inner .slider__input {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  justify-content: center;
  margin-top: 46px;
}
.slide .slider__content .slider__inner .slider__input input {
  background: #d50c0d none repeat scroll 0 0;
  border: 1px solid #d50c0d;
  border-radius: 5px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  height: 70px;
  line-height: 70px;
  margin: 0 3px;
  padding: 0 30px;
  width: 600px;
  outline: none;
}
.slide .slider__content .slider__inner .slider__input input.res__search {
  width: 400px;
}
.slide .slider__content .slider__inner .slider__input .src__btn {
  margin-left: 3px;
}
.slide .slider__content .slider__inner .slider__input .src__btn a {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  height: 70px;
  line-height: 67px;
  padding: 0 33px;
}

.slider--four .slide .slider__content .slider__inner h1 {
  color: #fefefe;
  font-family: "Open Sans", sans-serif;
  font-size: 72px;
  font-weight: 400;
  letter-spacing: 3px;
  line-height: 61px;
  margin-bottom: 38px;
}

.slider--four .slider__btn {
  margin-top: 38px;
}

.slider__input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #fff;
  opacity: 1;
}

.slider__input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #fff;
  opacity: 1;
}

.slider__input input::-moz-placeholder {
  color: #fff;
  opacity: 1;
}

.slider__input input::-ms-input-placeholder {
  color: #fff;
  opacity: 1;
}

.slider__input input::placeholder {
  color: #fff;
  opacity: 1;
}

.slider--two .slider__input input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #444444;
}

.slider--two .slider__input input:-ms-input-placeholder {
  /* IE 10+ */
  color: #444444;
}

.slider--two .slider__input input::-moz-placeholder {
  color: #444444;
}

.slider--two .slider__input input::-ms-input-placeholder {
  color: #444444;
}

.slider--two .slider__input input::placeholder {
  color: #444444;
}

.slider--two .slide .slider__content .slider__inner .slider__input {
  background: #fff none repeat scroll 0 0;
  margin-top: 150px;
  padding: 35px 0;
}

.slider--two .slide .slider__content .slider__inner .slider__input input {
  background: #feedb6 none repeat scroll 0 0;
  border: 1px solid #feedb6;
  color: #444444;
}

.slider--two .slider__text__img {
  text-align: right;
}

.slider--two .slider__text__img img.layear-2 {
  margin-top: -43px;
}

.slide__pizza {
  left: -327px;
  position: absolute;
  top: -263px;
  z-index: 1;
}

.slide__pizza--2 {
  left: -22%;
  position: absolute;
  top: -59%;
  z-index: 1;
}

.slide__pizza--3 {
  bottom: -21%;
  position: absolute;
  right: -35%;
  z-index: 1;
}

.slider--three .slide .slider__content .slider__inner h2 {
  color: #444444;
  font-family: "Alegreya", serif;
  font-size: 50px;
  font-weight: 400;
}

.slider--three .slide .slider__content .slider__inner h1 {
  color: #d50c0d;
  font-family: "Alegreya", serif;
  font-size: 200px;
  font-weight: 700;
  line-height: 184px;
  margin-bottom: 76px;
  letter-spacing: 3px;
}

.slider__area {
  overflow: hidden;
}

.slider__fixed--height {
  height: 450px;
}

.slide.bg-image--1 {
  -webkit-animation: prrtzoomin 10s ease-in infinite;
  animation: prrtzoomin 10s ease-in infinite;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}

@-webkit-keyframes prrtzoomin {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 105% 105%;
  }
  100% {
    background-size: 100% 100%;
  }
}

@keyframes prrtzoomin {
  0% {
    background-size: 100% 100%;
  }
  50% {
    background-size: 105% 105%;
  }
  100% {
    background-size: 100% 100%;
  }
}
div.slide__pizza img {
  -webkit-animation: rotate 60s linear 0s infinite normal;
  animation: rotate 60s linear 0s infinite normal;
}

@-webkit-keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.slider__text__img img {
  position: relative;
}

.slider__text__img img.layear-1 {
  -webkit-animation: crFadeInUp 0.5s linear 0s 1 both;
  animation: crFadeInUp 0.5s linear 0s 1 both;
}

.slider__text__img img.layear-2 {
  -webkit-animation: crFadeInDown 0.5s linear 0s 1 both;
  animation: crFadeInDown 0.5s linear 0s 1 both;
}

.slider__text__img {
  overflow: hidden;
}

@-webkit-keyframes crFadeInUp {
  from {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes crFadeInUp {
  from {
    -webkit-transform: translateY(50%);
    transform: translateY(50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@-webkit-keyframes crFadeInDown {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes crFadeInDown {
  from {
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    opacity: 0;
  }
  to {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .slide__pizza--2 img {
    width: 90%;
  }
  .slide__pizza--3 img {
    width: 81%;
  }
  .slide__pizza--3 {
    bottom: -16%;
    right: -54%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slider__text__img img.layear-1 {
    width: 93%;
  }
  .slider__text__img img.layear-2 {
    width: 69%;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    padding: 35px 21px;
  }
}
@media only screen and (max-width: 767px) {
  .slider__text__img img.layear-1 {
    width: 93%;
  }
  .slider__text__img img.layear-2 {
    width: 69%;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    padding: 35px 21px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .slider--two
    .slide
    .slider__content
    .slider__inner
    .slider__input
    input
    + input {
    margin-top: 30px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input .src__btn {
    margin-top: 30px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input input {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .slide .slider__content .slider__inner h1 {
    font-size: 22px;
    line-height: 20px;
  }
  .slide .slider__content .slider__inner h2 {
    font-size: 37px;
  }
  .slide .slider__content .slider__inner .slider__input {
    display: block;
  }
  .slide .slider__content .slider__inner .slider__input input.res__search,
  .slide .slider__content .slider__inner .slider__input .src__btn {
    margin-top: 30px;
  }
  .fullscreen {
    min-height: 80vh;
  }
}
/*======= Service Styles =======*/
.service__wrapper {
  padding: 100px 35px 100px 95px;
}
.service__wrapper .service {
  margin-top: 30px;
}
.service__wrapper .service .service__title {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 18px;
}
.service__wrapper .service .service__title .ser__icon {
  min-width: 50px;
}
.service__wrapper .service .service__title .ser__icon img {
  min-height: 55px;
}
.service__wrapper .service .service__title h2 {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  -moz-align-items: flex-end;
  align-items: flex-end;
  color: #444444;
  font-size: 22px;
  padding-left: 15px;
}
.service__wrapper .service .service__details p {
  color: #959595;
  font-size: 14px;
  padding-right: 10px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .service__wrapper .service .service__title h2 {
    font-size: 17px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .service__wrapper .service .service__title h2 {
    font-size: 17px;
  }
}
@media only screen and (max-width: 575px) {
  .service__wrapper {
    padding: 100px 30px;
  }
  .service__wrapper .service .service__title h2 {
    font-size: 17px;
  }
}
/*======= Service Styles 02 =======*/
.service__container {
  padding-left: 50px;
  padding-right: 50px;
}

.service--2 {
  margin-bottom: 10px;
  margin-left: -10px;
  margin-right: -10px;
}
.service--2 .service__inner {
  background: #f6f6f6 none repeat scroll 0 0;
  padding: 50px;
  text-align: center;
}
.service--2 .service__inner .service__content {
  background: #d8ead8 none repeat scroll 0 0;
  padding: 46px;
}
.service--2 .service__inner .service__content h2 {
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  padding-top: 20px;
  position: relative;
  text-transform: uppercase;
  display: inline-block;
}
.service--2 .service__inner .service__content h2::before {
  background: #444444 none repeat scroll 0 0;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  top: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 85px;
}
.service--2 .service__inner .service__content .ser__icon {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 100%;
  height: 160px;
  line-height: 160px;
  margin: 40px auto auto;
  width: 160px;
}
.service--2 .service__inner .service__content .ser__icon img {
  max-height: 80px;
}

.service__inner {
  position: relative;
}

.service--2 .service__inner .service__hover__action {
  background-color: rgb(96, 186, 98);
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  text-align: left;
  top: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 2;
}
.service--2 .service__inner .service__hover__action::before {
  background-image: url("images/shape/service/2.png");
  background-repeat: no-repeat;
  background-size: cover;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.service--2 .service__inner .service__hover__action .service__hover__inner {
  left: 30px;
  padding-right: 30px;
  position: absolute;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.service--2
  .service__inner
  .service__hover__action
  .service__hover__inner
  h4
  a {
  color: #ffffff;
  display: inline-block;
  font-family: Open Sans;
  font-size: 18px;
  font-weight: 700;
  padding-top: 16px;
  text-transform: uppercase;
}
.service--2
  .service__inner
  .service__hover__action
  .service__hover__inner
  h4
  a::before {
  background: #aed9af none repeat scroll 0 0;
  content: "";
  height: 1px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
}
.service--2 .service__inner .service__hover__action .service__hover__inner p {
  color: #ffffff;
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 20px;
  margin-top: 18px;
}
.service--2
  .service__inner
  .service__hover__action
  .service__hover__inner
  ul
  li
  a {
  color: #ffffff;
  font-size: 16px;
}

.service__hover__inner ul li + li {
  padding-top: 10px;
}

.service--2:hover .service__hover__action {
  opacity: 1;
}

@media only screen and (max-width: 575px) {
  .service--2 .service__inner {
    padding: 10px;
  }
  .service__container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .service--2 .service__inner .service__content {
    padding: 40px;
  }
  .service--2 .service__inner .service__content .ser__icon {
    height: 80px;
    line-height: 80px;
    margin: 40px auto auto;
    width: 80px;
  }
  .service--2 .service__inner .service__content .ser__icon img {
    max-height: 32px;
  }
  .service--2 .service__inner .service__hover__action .service__hover__inner p {
    display: none;
  }
  .service__hover__inner ul {
    margin-top: 15px;
  }
  .service__hover__inner ul li + li {
    padding-top: 5px;
  }
}
/*-----------------------
	Food Category
-------------------------*/
.food__category__wrapper .food__item {
  margin-top: 40px;
  position: relative;
  overflow: hidden;
}
.food__category__wrapper .food__item:hover .food__title h2 {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}
.food__category__wrapper .food__item:hover .food__thumb a img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.food__category__wrapper .food__item .food__thumb a {
  display: block;
}
.food__category__wrapper .food__item .food__thumb a img {
  width: 100%;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.food__category__wrapper .food__item .food__title {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
}
.food__category__wrapper .food__item .food__title h2 {
  display: block;
  position: relative;
  -webkit-transform: translateY(0px);
  transform: translateY(0px);
  z-index: 1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.food__category__wrapper .food__item .food__title h2:before {
  position: absolute;
  content: "";
  width: 302px;
  z-index: -1;
  top: 50%;
  height: 99px;
  background: url("images/icon/lg-shape/1.png");
  left: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.food__category__wrapper .food__item .food__title h2 a {
  color: #fff;
  font-size: 26px;
  color: #ffffff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.food__category__wrapper .food__item .food__title h2 a:hover {
  color: #d50c0d;
}

/*---------------------------
	Special Menu
-----------------------------*/
.special__food__menu {
  padding: 0 198px;
}
.special__food__menu .food__nav {
  border: 0 none;
  margin-bottom: 80px;
}
.special__food__menu .food__nav a {
  border: 1px dashed #646465;
  color: #fff;
  font-family: "Alegreya", serif;
  font-size: 18px;
  height: 40px;
  line-height: 38px;
  padding: 0 24px;
  text-transform: capitalize;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.special__food__menu .food__nav a.active {
  background: #d50c0d none repeat scroll 0 0;
  border: 1px dashed #fff;
  color: #fff;
}
.special__food__menu .food__nav a:hover {
  color: #fff;
}
.special__food__menu .fd__tab__content .single__tab__panel .tab__content__wrap {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__thumb {
  margin-right: 20px;
  max-width: 100px;
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details {
  -ms-flex-preferred-size: 80%;
  flex-basis: 80%;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__title__prize {
  border-bottom: 1px dashed #fff;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
  padding-bottom: 11px;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__title__prize
  h4 {
  line-height: 20px;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__title__prize
  h4
  a {
  color: #fff;
  font-size: 26px;
  font-weight: 700;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__title__prize
  h4
  a:hover {
  color: #d50c0d;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__title__prize
  span.menu__prize {
  -webkit-align-self: flex-end;
  -ms-flex-item-align: end;
  align-self: flex-end;
  color: #d50c0d;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 37px;
  margin-left: 19px;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details {
  margin-top: 13px;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details
  p {
  color: #fff;
  font-size: 14px;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details
  .delivery__time__rating {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details
  .delivery__time__rating
  .fd__rating {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details
  .delivery__time__rating
  .fd__rating
  li
  i {
  color: #fde101;
}
.special__food__menu
  .fd__tab__content
  .single__tab__panel
  .tab__content__wrap
  .single__tab__content
  .food__menu
  .food__menu__details
  .fd__menu__details
  .delivery__time__rating
  .fd__rating
  li
  i.rating__opasity {
  color: #5a5314;
}

.food__menu__prl {
  padding: 130px 0;
}

.single__tab__content .food__menu + .food__menu {
  margin-top: 110px;
}

.fd__rating li + li {
  margin-left: 7px;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .special__food__menu {
    padding: 0 53px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .special__food__menu {
    padding: 0 39px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap {
    display: -webkit-box;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    -moz-justify-content: space-between;
    justify-content: space-between;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    padding-bottom: 0;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    h4
    a {
    font-size: 23px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    span.menu__prize {
    margin-left: 0px;
    font-size: 30px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__details {
    margin-top: 9px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__details
    p {
    font-size: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .special__food__menu {
    padding: 0 30px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap {
    display: block;
  }
}
@media only screen and (max-width: 767px) {
  .special__food__menu {
    padding: 0 30px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap {
    display: block;
  }
  .special__food__menu .food__nav a {
    padding: 0 15px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    h4
    a {
    font-size: 22px;
  }
}
@media only screen and (max-width: 575px) {
  .special__food__menu {
    padding: 0 20px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap {
    display: block;
  }
  .special__food__menu .food__nav a {
    padding: 0 15px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu,
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize {
    display: block;
  }
  .special__food__menu .food__nav a {
    padding: 0 28px;
    margin-bottom: 10px;
  }
  .special__food__menu .food__nav {
    border: 0 none;
    margin-bottom: 40px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    h4
    a {
    font-size: 18px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    span.menu__prize {
    margin-left: 0;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__details
    p {
    font-size: 12px;
  }
  .food__menu__details {
    margin-top: 20px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__thumb {
    margin-right: 0;
    max-width: 100%;
  }
  .single__tab__content .food__menu + .food__menu {
    margin-top: 50px;
  }
  .single__tab__content + .single__tab__content {
    margin-top: 50px;
  }
}
/*----------------------------------
	Download App Area
 -----------------------------------*/
.inline__image__css--1 {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
}

.app__phone {
  bottom: -74px;
  left: -59px;
  position: absolute;
}

.app__download__container .app__download__inner {
  padding: 212px 56px 212px 0;
  text-align: right;
}
.app__download__container .app__download__inner h2 {
  color: #ffffff;
  font-size: 50px;
  font-style: italic;
  font-weight: 400;
}
.app__download__container .app__download__inner h6 {
  color: #ffffff;
  font-size: 36px;
  font-style: italic;
  font-weight: 400;
  margin-top: 4px;
}
.app__download__container .dwn__app__list {
  text-align: right;
}
.app__download__container .dwn__app__list li {
  display: inline-block;
}
.app__download__container .dwn__app__list li:first-child {
  margin-right: 16px;
}
.app__download__container .dwn__app__list li a {
  display: block;
}

.bg__shape--1 {
  position: relative;
}
.bg__shape--1::before {
  background: #f6f6f6 none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 470px;
  left: 0;
  position: absolute;
  width: 100%;
}

/*----------------------------------
	Download App Area 02
 -----------------------------------*/
.food__stuff__wrap {
  padding: 100px 0;
}

.food__stuff__area {
  padding: 70px 0;
}

.food__sideimg--1 {
  bottom: 0;
  height: 100%;
  left: 17%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.food__sideimg--2 {
  bottom: 70px;
  left: 0;
  position: absolute;
}

.food__sideimg--3 {
  bottom: 70px;
  position: absolute;
  right: 0;
}

.food__stuff__link li + li {
  margin-left: 20px;
}

.food__stuff__inner h2 {
  color: #ffffff;
  font-family: Open Sans;
  font-size: 50px;
  font-weight: 700;
  line-height: 47px;
}

.food__stuff__inner h3 {
  color: #ffffff;
  font-size: 36px;
  font-weight: 400;
  line-height: 46px;
  margin-top: 19px;
}

.food__stuff__link {
  margin-top: 29px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .food__sideimg--1 {
    left: 8%;
    top: 35px;
    -webkit-transform: translateY(0%);
    transform: translateY(0%);
  }
  .food__sideimg--1 img {
    width: 81%;
  }
  .food__sideimg--2 img {
    width: 53%;
  }
  .food__stuff__inner h2 {
    font-size: 42px;
  }
  .app__phone img {
    width: 82%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .app__phone {
    bottom: 37px;
    left: -51px;
    position: absolute;
    width: 52%;
  }
  .app__download__container .app__download__inner h6 {
    font-size: 26px;
  }
  .food__sideimg--1 {
    display: none;
  }
  .food__sideimg--2 {
    left: -54px;
  }
  .app__phone img {
    width: 85%;
  }
}
@media only screen and (max-width: 767px) {
  .app__phone {
    bottom: 71px;
    left: -110px;
    position: absolute;
    width: 60%;
  }
  .food__sideimg--1 {
    display: none;
  }
  .single__tab__content + .single__tab__content {
    margin-top: 110px;
  }
  .app__phone img {
    width: 93%;
  }
  .app__download__container .app__download__inner {
    padding: 132px 56px 60px 0;
  }
  .app__download__container .app__download__inner h2,
  .app__download__container .app__download__inner h6 {
    color: #000;
  }
  .app__phone img {
    display: none;
  }
  .inline__image__css--1 {
    background-size: 0 auto;
  }
}
@media only screen and (max-width: 575px) {
  .app__phone {
    display: none;
  }
  .app__download__container .app__download__inner {
    padding: 100px 56px 100px 0;
  }
  .app__download__container .dwn__app__list li {
    display: block;
  }
  .app__download__container .dwn__app__list li + li {
    margin-top: 20px;
  }
  .food__sideimg--2 {
    display: none;
  }
  .food__sideimg--3 {
    display: none;
  }
  .food__stuff__link {
    display: block !important;
  }
  .food__stuff__link li + li {
    margin-top: 20px;
  }
  .food__stuff__inner h2 {
    font-size: 40px;
  }
  .food__stuff__inner h3 {
    font-size: 30px;
    line-height: 39px;
  }
  .app__download__container .app__download__inner h6 {
    font-size: 28px;
  }
  .app__download__container .app__download__inner h2 {
    font-size: 31px;
  }
  .app__download__container .app__download__inner {
    padding: 65px 16px 60px 0;
  }
}
/*----------------------------------
	Clint Demand Product
 -----------------------------------*/
.clint__demand {
  -webkit-box-shadow: 0 0 32px rgba(139, 139, 139, 0.1);
  box-shadow: 0 0 32px rgba(139, 139, 139, 0.1);
  margin-top: 40px;
  position: relative;
  text-align: center;
}
.clint__demand .clnt__demand__thumb img {
  width: 100%;
}
.clint__demand .clnt__thumb__hover {
  position: relative;
  top: -113px;
}
.clint__demand .clnt__thumb__hover .demand__food__prize {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 100%;
  height: 70px;
  left: 50%;
  line-height: 64px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 70px;
}
.clint__demand .clnt__thumb__hover .demand__food__prize span {
  color: #ffffff;
  font-family: "Alegreya", serif;
  font-size: 30px;
  font-weight: 700;
}
.clint__demand .clint__demand__inner {
  margin-top: -95px;
  padding-bottom: 30px;
  background: #fff;
}
.clint__demand .clint__demand__inner h4 {
  font-size: 26px;
  margin-bottom: 10px;
  padding-bottom: 7px;
  position: relative;
  text-transform: capitalize;
}
.clint__demand .clint__demand__inner h4::before {
  background-image: url("images/icon/shape/line.png");
  background-position: center center;
  bottom: 0;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 140px;
}
.clint__demand .clint__demand__inner .clt__delevery__time p {
  font-size: 14px;
}

.rating li {
  display: inline-block;
  margin: 0 2px;
}
.rating li.rating__opasity i {
  color: #fcf5b5;
}
.rating li i {
  color: #fde101;
  font-size: 18px;
}

.clt__delevery__time {
  margin-bottom: 13px;
}

.clnt__thumb__hover__action img {
  max-height: 184px;
}

/*-----------------------
	Testimonial Area
-------------------------*/
.testimonial__activation--1 {
  background-image: url("images/testimonial/img/1.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto auto;
  content: "";
}

.testimonial {
  padding-bottom: 85px;
  padding-top: 40px;
  position: relative;
}
.testimonial::before {
  color: #bfe3c0;
  content: "\f1b2";
  font-family: "Material-Design-Iconic-Font";
  font-size: 64px;
  left: 0;
  position: absolute;
  top: 0;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.testimonial::after {
  color: #bfe3c0;
  content: "\f1b2";
  font-family: "Material-Design-Iconic-Font";
  font-size: 64px;
  right: 0;
  position: absolute;
  bottom: 0px;
}
.testimonial .testimonial__thumb {
  margin-bottom: 18px;
}
.testimonial .testimonial__details h4 {
  color: #333333;
  font-family: Open Sans;
  font-weight: 400;
  text-transform: capitalize;
}
.testimonial .testimonial__details h6 {
  color: #333333;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 400;
  margin-top: 6px;
  text-transform: capitalize;
}
.testimonial .testimonial__details p {
  color: #333333;
  font-size: 20px;
  font-style: italic;
  line-height: 36px;
  margin-top: 17px;
  padding: 0 6%;
}

@media only screen and (max-width: 767px) {
  .testimonial .testimonial__details p {
    font-size: 19px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial .testimonial__details p {
    font-size: 14px;
    line-height: 30px;
    padding: 0 2%;
  }
  .testimonial__activation--1 {
    margin-left: 0;
    margin-right: 0;
  }
}
.single__testimonial {
  margin-left: -50px;
  margin-right: -50px;
}

.testimonial-2 {
  -ms-flex-preferred-size: 42%;
  flex-basis: 42%;
  background: #ffffff none repeat scroll 0 0;
  border-radius: 10px;
  margin-left: 50px;
  margin-right: 50px;
  margin-top: 0;
  padding: 30px 30px 30px 70px;
  position: relative;
}
.testimonial-2::before {
  color: #f0c1c1;
  content: "\f1b2";
  font-family: Material-Design-Iconic-Font;
  font-size: 50px;
  left: 25px;
  position: absolute;
  top: 23px;
}
.testimonial-2 p {
  color: #444444;
  font-size: 18px;
  line-height: 30px;
}
.testimonial-2 .fd__test__info h6 {
  color: #444444;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  margin-top: 13px;
  text-transform: uppercase;
}
.testimonial-2 .fd__test__info span {
  color: #444444;
  display: inline-block;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 7px;
}

.testimonial__activation--2 .dot__cus {
  margin-top: 69px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial-2 {
    -ms-flex-preferred-size: 40%;
    flex-basis: 40%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial-2 {
    -ms-flex-preferred-size: 42%;
    flex-basis: 42%;
    margin-left: 30px;
    margin-right: 30px;
    padding: 30px;
  }
  .testimonial-2 p {
    font-size: 16px;
    line-height: 29px;
  }
  .single__testimonial {
    margin-left: -30px;
    margin-right: -30px;
  }
  .testimonial-2 {
    margin-left: 30px;
    margin-right: 30px;
    margin-top: 0;
    padding: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .testimonial-2 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    padding: 30px;
  }
  .testimonial-2 p {
    font-size: 14px;
    line-height: 24px;
  }
  .testimonial-2 + .testimonial-2 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 575px) {
  .testimonial-2 {
    padding: 30px;
  }
  .testimonial-2 + .testimonial-2 {
    margin-top: 40px;
  }
  .testimonial-2 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
.testimonial-inner--3 {
  position: relative;
  padding-right: 24px;
}
.testimonial-inner--3::before {
  background-image: url("images/icon/shape/quota.png");
  background-repeat: no-repeat;
  content: "";
  height: 60px;
  left: -19px;
  position: absolute;
  top: -29px;
  width: 80px;
  z-index: 2;
}
.testimonial-inner--3::after {
  background: rgba(0, 0, 0, 0) url("images/icon/shape/quota.png") repeat scroll
    0 0;
  bottom: -22px;
  content: "";
  height: 60px;
  position: absolute;
  right: 13px;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  width: 80px;
}
.testimonial-inner--3 .testimonial__inner {
  background: #60ba62 none repeat scroll 0 0;
  padding: 20px 66px 20px 30px;
}
.testimonial-inner--3 .testimonial__inner .testimonial__content {
  border-bottom: 1px solid #76d778;
  border-top: 1px solid #76d778;
  padding-bottom: 20px;
  padding-top: 20px;
}
.testimonial-inner--3 .testimonial__inner .testimonial__content p {
  color: #ffffff;
  font-family: "Alegreya", serif;
  font-size: 30px;
  line-height: 46px;
}
.testimonial-inner--3 .testimonial__inner .testimonial__content .test__info {
  margin-top: 18px;
}
.testimonial-inner--3 .testimonial__inner .testimonial__content .test__info h4 {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 9px;
  text-transform: capitalize;
}
.testimonial-inner--3
  .testimonial__inner
  .testimonial__content
  .test__info
  span {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.testimonial--3 {
  padding: 160px 0 130px;
}

.testimonia__activation--3 .owl-stage-outer {
  padding-top: 32px;
  padding-left: 14px;
  padding-bottom: 40px;
  margin-right: 4px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .testimonial--3 {
    background-position: 88% bottom;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .testimonial--3 {
    background-position: 85% bottom;
  }
  .subscribe__inner.subscribe--3 {
    padding: 84px 40px 30px;
  }
  .subs__address__content .subs__address .sbs__address__icon {
    padding-right: 7px;
  }
  .subs__address__content .subs__address .subs__address__details p,
  .subs__address__content .subs__address .subs__address__details p a {
    font-size: 13px;
  }
  .subscribe__inner.subscribe--3 .enter__email__address input {
    -ms-flex-preferred-size: 74.5%;
    flex-basis: 74.5%;
  }
}
@media only screen and (max-width: 767px) {
  .subscribe__inner.subscribe--3 {
    padding: 84px 30px 30px;
  }
  .subscribe__inner.subscribe--3 .enter__email__address input {
    -ms-flex-preferred-size: 67.5%;
    flex-basis: 67.5%;
  }
  .subs__address__content {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .subs__address:last-child {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .subs__address:nth-child(2) {
    margin-top: 30px;
  }
}
.testimonial-inner--4 .testimonial__inner {
  padding: 85px 0;
  text-align: center;
}
.testimonial-inner--4
  .testimonial__inner
  .testimonial-text-content
  .testimonial__content {
  background: #d50c0d none repeat scroll 0 0;
  margin-bottom: 22px;
  padding: 25px 10%;
}
.testimonial-inner--4
  .testimonial__inner
  .testimonial-text-content
  .testimonial__content
  p {
  color: #fff;
  font-size: 18px;
  line-height: 30px;
}

.testimonial-image-slider .test__info h4 {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 4px;
  text-transform: capitalize;
}
.testimonial-image-slider .test__info span {
  color: #ffffff;
  font-size: 14px;
  text-transform: capitalize;
}

.test__icon {
  margin-bottom: 27px;
}
.test__icon i {
  border: 1px solid #f9fafb;
  border-radius: 100%;
  color: #f9fafb;
  font-size: 24px;
  height: 36px;
  line-height: 33px;
  width: 36px;
}

.test__img__thumb {
  margin: auto auto 13px;
  padding-top: 35px;
  text-align: center;
}

.testimonial-inner--4 .slick-active.slick-center .test__img__thumb {
  padding-bottom: 30px;
}

.testimonial-inner--4 .slick-active.slick-center .test__img__thumb img {
  -webkit-transform: scale(1.5);
  transform: scale(1.5);
}

.slick-slide img {
  display: inline-block;
}

.dot__cus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-top: 25px;
}
.dot__cus li {
  padding: 0 10px;
  position: relative;
}
.dot__cus li::before {
  color: #fff;
  content: "-";
  font-size: 13px;
  position: absolute;
  right: 0;
}
.dot__cus li.slick-active button {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 100%;
  font-weight: 700;
  height: 25px;
  outline: 1px solid transparent;
  width: 25px;
}
.dot__cus li button {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #ffffff;
  font-size: 14px;
  outline: medium none;
}

.dot__cus li:last-child::before {
  display: none;
}

.owl-carousel .owl-item img {
  margin: auto;
  width: auto;
}

/*--------------------------
	Blog Arae
---------------------------*/
.blog {
  -webkit-box-shadow:
    0 0 10px rgba(96, 186, 98, 0.1) inset,
    0 0 0 #000;
  box-shadow:
    0 0 10px rgba(96, 186, 98, 0.1) inset,
    0 0 0 #000;
  margin-top: 40px;
  text-align: center;
  -webkit-transition: -webkit-box-shadow 1s ease 0s;
  transition: -webkit-box-shadow 1s ease 0s;
  transition: box-shadow 1s ease 0s;
  transition:
    box-shadow 1s ease 0s,
    -webkit-box-shadow 1s ease 0s;
}
.blog .blog__details {
  padding: 35px 13px;
  padding-bottom: 43px;
}
.blog .blog__details h2 {
  color: #3a3a3a;
  font-size: 26px;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.blog .blog__details span {
  color: #959595;
  font-size: 14px;
}
.blog .blog__details p {
  color: #959595;
  font-size: 14px;
  margin-top: 9px;
  padding: 0 5%;
}
.blog .blog__thumb {
  overflow: hidden;
}
.blog .blog__thumb a {
  display: block;
}
.blog .blog__thumb a img {
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
}

.blog__btn {
  margin-top: 29px;
}

.blog__thumb a,
.banner {
  position: relative;
  display: block;
}

.banner:hover::before,
.banner:hover::after {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.banner::after {
  -webkit-transform-origin: 100% 100% 0;
  transform-origin: 100% 100% 0;
}

.blog__thumb a::before {
  background: #fff none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0.7;
  position: absolute;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  z-index: 5;
}

.blog:hover .blog__thumb a img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
  z-index: 1;
}

.blog:hover .blog__thumb a::before {
  opacity: 0;
}

.blog__thumb a img {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.blog__btn .food__btn {
  padding: 0 26px;
}

.blog--2 .blog__btn .food__btn.grey--btn {
  color: #959595;
  font-size: 18px;
  font-weight: 400;
  padding: 0 27px;
}

.blog--2 .blog__btn .food__btn.grey--btn:hover {
  color: #fff;
}

/*======= About Styles =======*/
.fd__about p {
  color: #666666;
  font-size: 14px;
  margin-top: 11px;
  padding-right: 20%;
}
.fd__about .section__title .title__line {
  margin-bottom: 32px;
  margin-top: 16px;
}
.fd__about .section__title p {
  color: #666666;
  font-size: 14px;
}
.fd__about .fd__about h4 {
  color: #666666;
  font-size: 22px;
  font-weight: 700;
  text-transform: capitalize;
}

.about__btn {
  margin-top: 28px;
}

.about__btn .food__btn {
  padding: 0 25px;
}

.about {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.about .about__details {
  background: #d50c0d none repeat scroll 0 0;
  padding: 25px 28px 36px;
}
.about .about__details h2 {
  color: #ffffff;
  font-size: 20px;
}
.about .about__details h2 span {
  font-size: 30px;
  text-transform: capitalize;
}
.about .about__details p {
  color: #ffffff;
  font-family: Alegreya;
  font-size: 20px;
  font-weight: 400;
  margin-top: 9px;
}

.video__owl__activation.tab-pane:not(.active) {
  display: none;
}

.pro__offer {
  height: 180px;
  position: absolute;
  right: -50px;
  top: -50px;
  width: 180px;
  z-index: 2;
}
.pro__offer::before {
  background: #ffffff none repeat scroll 0 0;
  border-radius: 100%;
  content: "";
  height: calc(100% - 8px);
  left: -10px;
  position: absolute;
  top: 9px;
  -webkit-transform: rotate(-16deg);
  transform: rotate(-16deg);
  width: calc(100% + 20px);
  z-index: -1;
}
.pro__offer .pro__offer__inner {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 100%;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 3;
}
.pro__offer .pro__offer__inner .pro__offer__inner__inner {
  bottom: 37px;
  left: 26px;
  position: absolute;
  z-index: 5;
}
.pro__offer .pro__offer__inner .pro__offer__inner__inner span {
  display: block;
  color: #ffffff;
  font-family: "Alegreya", serif;
  font-size: 20px;
  font-weight: 700;
}
.pro__offer .pro__offer__inner .pro__offer__inner__inner span.fd__diccount {
  color: #ffffff;
  font-family: "Alegreya", serif;
  font-size: 50px;
  font-weight: 700;
  margin-bottom: 18px;
  -webkit-transform: rotate(13deg);
  transform: rotate(13deg);
}

.about.bg--yellow .about__details {
  background: #fdc50e none repeat scroll 0 0;
}

.about.bg--yellow .pro__offer .pro__offer__inner {
  background: #fdc50e none repeat scroll 0 0;
}

/*======= Banner Styles =======*/
/*------------------------
	Pizza Arae
--------------------------*/
.pizza {
  background: #ffffff none repeat scroll 0 0;
  padding: 0;
  text-align: center;
  margin-top: 40px;
}
.pizza .pizza__thumb {
  position: relative;
}
.pizza .pizza__thumb img {
  width: 100%;
}
.pizza .pizza__thumb .pizza__hover__action {
  background: #fdc50e none repeat scroll 0 0;
  border-radius: 100%;
  height: 70px;
  line-height: 65px;
  opacity: 0;
  position: absolute;
  right: 19%;
  text-align: center;
  top: 34px;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 70px;
}
.pizza .pizza__thumb .pizza__hover__action span {
  color: #ffffff;
  font-family: "Alegreya", serif;
  font-size: 30px;
  font-weight: 700;
}
.pizza .pizza__details {
  padding: 0 9px;
  margin-top: 14px;
}
.pizza .pizza__details .pizza__title {
  color: #ffffff;
  font-size: 26px;
  position: relative;
  z-index: 2;
}
.pizza .pizza__details .pizza__title::before {
  background-image: url("images/icon/lg-shape/2.png");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  content: "";
  height: 67px;
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  width: 100%;
  z-index: -1;
}
.pizza .pizza__details .pizza__title a {
  color: #fff;
}
.pizza .pizza__details p {
  color: #959595;
  font-size: 14px;
  margin-bottom: 15px;
  margin-top: 40px;
  padding: 0 22px;
}

.pizza:hover .pizza__hover__action {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}

.pizza__btn {
  -webkit-transform: translateY(20px);
  transform: translateY(20px);
}

.order-now-area {
  margin-top: 157px;
  padding-top: 134px;
}

.bg__shape--2 {
  padding-bottom: 187px;
  padding-top: 77px;
  position: relative;
  background-image: url("images/shape/1.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  content: "";
  height: 100%;
  width: 100%;
}

.order__now__inner h6 {
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  text-transform: capitalize;
}
.order__now__inner h2 {
  color: #ffffff;
  font-family: Open Sans;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  margin-top: 3px;
  text-transform: uppercase;
}
.order__now__inner p {
  color: #ffffff;
  font-family: Alegreya;
  font-size: 26px;
  font-weight: 700;
  line-height: 30px;
  margin-top: 30px;
}

/*------------------------
    Resturant Food Menu
--------------------------*/
.single__resturent__food {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
}

.single__resturent__food {
  margin-bottom: 10px;
  padding: 0 5px;
}
.single__resturent__food .resturent__thumb {
  min-width: 300px;
}
.single__resturent__food .resturent__thumb img {
  width: 100%;
}
.single__resturent__food .resturent__details {
  background: #ffffff none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 32px rgba(139, 139, 139, 0.1);
  box-shadow: 0 0 32px rgba(139, 139, 139, 0.1);
  padding: 20px;
  text-align: center;
}
.single__resturent__food .resturent__details ul li {
  margin: 0 16px;
  color: #d50c0d;
  font-family: Alegreya;
  font-size: 14px;
  text-transform: capitalize;
}
.single__resturent__food .resturent__details ul li span {
  display: block;
  font-size: 26px;
  font-style: italic;
  font-weight: 700;
}
.single__resturent__food .resturent__details h4 {
  font-size: 26px;
  font-weight: 700;
  margin-top: 40px;
}
.single__resturent__food .resturent__details h6 {
  color: #959595;
  font-size: 18px;
  font-weight: 700;
  margin-top: 8px;
}
.single__resturent__food .resturent__details p {
  color: #959595;
  font-size: 14px;
  line-height: 24px;
  margin-top: 11px;
}

.resturent__food__menu {
  margin-left: -5px;
  margin-right: -5px;
}

.res__btn {
  margin-top: 29px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .resturent__food__menu .plr--250px {
    padding: 0 148px;
  }
  .single__resturent__food {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .resturent__food__menu .plr--250px {
    padding: 0 30px;
  }
  .single__resturent__food {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .resturent__food__menu .plr--250px {
    padding: 0 30px;
  }
  .single__resturent__food {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .resturent__food__menu .plr--250px {
    padding: 0 30px;
  }
  .single__resturent__food {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .single__resturent__food .resturent__thumb {
    min-width: auto;
    width: 100%;
  }
}
/*------------------------
    Popular Food Menu
--------------------------*/
.popular__food {
  background: #fff none repeat scroll 0 0;
  margin-left: 22px;
  margin-top: 70px;
  padding-bottom: 30px;
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
}

.pp_food__thumb {
  position: relative;
}

.pp__food__prize {
  background: #60ba62 none repeat scroll 0 0;
  border-radius: 100%;
  height: 80px;
  left: -30px;
  position: absolute;
  top: -30px;
  width: 80px;
  line-height: 80px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.pp__food__prize.offer span.new {
  display: block;
  font-size: 12px;
  line-height: 20px;
  text-transform: capitalize;
  line-height: 30px;
  padding-top: 7px;
}

.pp__food__prize.offer {
  line-height: inherit;
}

.pp__food__prize span {
  color: #ffffff;
  font-size: 24px;
}

.pp__fd__icon {
  margin-top: 30px;
}

.pp__food__details {
  margin-top: 12px;
}

.pp__food__details h4 {
  color: #777777;
  font-family: Open Sans;
  font-weight: 400;
  margin-bottom: 14px;
  text-transform: capitalize;
}

.pp__food__details p {
  color: #797979;
  margin-top: 4px;
}

.pp__food__bottom {
  border-top: 1px solid #eeeeee;
  margin-top: 28px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
}

.pp__fd__icon img {
  max-height: 35px;
}

.food__btn.btn--transparent {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #f2f2f2;
  color: #959595;
}

.pp_food__thumb a {
  display: block;
}

.popular__food:hover {
  -webkit-transform: translateY(-20px);
  transform: translateY(-20px);
}

.popular__food:hover .pp__food__prize {
  background: #d50c0d;
}

.pp_food__thumb a img {
  width: 100%;
}

.pp__food__prize.active {
  background: #d50c0d;
}

.pp__meta li a {
  color: #959595;
  font-size: 14px;
}
.pp__meta li a i {
  padding-right: 5px;
}

.pp__meta li + li {
  padding-left: 19px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .pp__food__details p {
    font-size: 14px;
  }
  .popular__food {
    margin-left: 9px;
  }
  .pp__food__bottom {
    padding-left: 12px;
    padding-right: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .popular__food {
    margin-left: 0;
  }
  .pp__food__bottom {
    padding-left: 10px;
    padding-right: 10px;
  }
}
/*======= Banner Styles =======*/
/*------------------------
    CounterUp Arae
--------------------------*/
.counter__up__inner {
  padding: 200px 130px;
}

.funfact .fact__icon {
  margin-bottom: 18px;
}
.funfact .fact__icon img {
  min-height: 60px;
}
.funfact .fact__count span.count {
  color: #fdc50e;
  display: block;
  font-family: "Alegreya", serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 32px;
}
.funfact .fact__title h2 {
  color: #959595;
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-weight: 400;
  margin-top: 9px;
}

.fanfact--2 .funfact .funfact__count__inner .fact__icon img {
  min-height: 30px;
}
.fanfact--2 .funfact .funfact__count__inner .fact__count span.count {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
}
.fanfact--2 .funfact .fact__title h2 {
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 700;
}

.fanfact--2 .counter__up__inner {
  padding: 90px 100px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .fanfact--2 .counter__up__inner {
    padding: 90px 50px;
  }
}
@media only screen and (max-width: 767px) {
  .fanfact--2 .counter__up__inner {
    padding: 90px 50px;
  }
}
@media only screen and (max-width: 575px) {
  .funfact:nth-child(n + 3) {
    margin-top: 40px;
  }
}
/*======= Header Styles =======*/
.feature {
  background: #fff none repeat scroll 0 0;
  -webkit-box-shadow: 0 0px 8px 0px rgba(1, 26, 27, 0.1);
  box-shadow: 0 0px 8px 0px rgba(1, 26, 27, 0.1);
  padding-bottom: 30px;
  padding-top: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.feature:hover {
  -webkit-box-shadow: 0 0px 16px 11px rgba(191, 191, 191, 0.1);
  box-shadow: 0 0px 16px 11px rgba(191, 191, 191, 0.1);
}
.feature .feature__thumb a img {
  max-height: 198px;
}
.feature .feature__details h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  font-weight: 700;
  margin-top: 24px;
}
.feature .feature__details h6 {
  color: #959595;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  margin-top: 8px;
}
.feature .feature__details p {
  color: #959595;
  font-size: 14px;
  line-height: 24px;
  margin-top: 23px;
  padding: 0 29px;
}

.chooseus__btn a.food__btn {
  padding: 0 29px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .feature .feature__details h4 {
    font-size: 20px;
  }
  .feature .feature__details h6 {
    font-size: 15px;
  }
  .feature .feature__details p {
    font-size: 14px;
    padding: 0 25px;
  }
}
/*---------------------------
  Choose Us Area
------------------------------*/
.choose__wrap p {
  color: #444444;
  font-size: 14px;
  padding: 0 17%;
}
.choose__wrap .fd__choose__list {
  margin-bottom: 22px;
  margin-top: 20px;
}
.choose__wrap .fd__choose__list li {
  color: #444444;
  font-size: 14px;
  font-weight: 700;
  margin: 0 15px;
}
.choose__wrap .chooseus__btn {
  margin-top: 45px;
}

.choose__img--1 {
  bottom: 0;
  position: absolute;
  right: 0;
}

.choose__img--2 {
  bottom: 0;
  left: 0;
  position: absolute;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .choose__img--2 img {
    width: 62%;
  }
  .choose__img--1 {
    right: -21%;
  }
  .choose__img--1 img {
    width: 58%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .choose__img--2 img {
    width: 56%;
  }
  .choose__img--1 {
    right: -34%;
  }
  .choose__img--1 img {
    width: 50%;
  }
}
@media only screen and (max-width: 767px) {
  .choose__img--2 img {
    width: 56%;
  }
  .choose__img--1 {
    right: -34%;
  }
  .choose__img--1 img {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .choose__img--2 {
    width: 35%;
  }
  .choose__img--2 img {
    width: 100%;
  }
  .choose__img--1 {
    display: none;
  }
  .choose__wrap p {
    padding: 0;
  }
  .choose__wrap .fd__choose__list {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}
/*---------------------------
  Special Offer Area
------------------------------*/
.food__offer {
  -webkit-box-shadow: 0 5px 13px rgba(111, 111, 111, 0.15);
  box-shadow: 0 5px 13px rgba(111, 111, 111, 0.15);
  margin-top: 30px;
  display: flex;
  flex-direction: column;
}
.food__offer .offer__thumb {
  overflow: hidden;
}
.food__offer .offer__thumb img {
  width: 100%;
}
.food__offer .offer__thumb .offer__product__prize {
  height: 63px;
  left: 0;
  line-height: 57px;
  position: absolute;
  top: 0;
  width: 106px;
  z-index: 1;
}
.food__offer .offer__thumb .offer__product__prize::before {
  background: #60ba62 none repeat scroll 0 0;
  content: "";
  height: 100%;
  margin-left: -72px;
  position: absolute;
  -webkit-transform: skewX(-25deg);
  transform: skewX(-25deg);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: -1;
}
.food__offer .offer__thumb .offer__product__prize span {
  color: #ffffff;
  display: block;
  font-size: 26px;
  font-weight: 700;
  margin-left: -25px;
}
.food__offer .offer__details {
  background: #fff none repeat scroll 0 0;
  padding: 35px 11px;
  position: relative;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.food__offer .offer__details h2 {
  font-size: 20px;
  margin-bottom: 18px;
  text-transform: capitalize;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.food__offer .offer__details p {
  color: #959595;
  font-size: 14px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.offer__details::after {
  -webkit-transform-origin: 100% 100% 0;
  transform-origin: 100% 100% 0;
}

/*--------------------------
	Team, Area
---------------------------*/
.team {
  -webkit-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
  -webkit-box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  margin-top: 40px;
  overflow: hidden;
}
.team .team__thumb {
  -webkit-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
}
.team .team__thumb img {
  width: 100%;
}
.team .team__content {
  padding: 10px 19px 8px;
  -webkit-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
  background: #fff;
}
.team .team__content p {
  color: #959595;
  font-size: 14px;
  margin-bottom: 20px;
}
.team .team__content .team__info {
  margin-bottom: 25px;
  padding-bottom: 17px;
  position: relative;
}
.team .team__content .team__info::before {
  background: #d50c0d none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  left: 50%;
  position: absolute;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 40px;
}
.team .team__content .team__info h4 {
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 14px;
  padding-top: 25px;
  text-transform: capitalize;
}
.team .team__content .team__info h6 {
  color: #959595;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-transform: uppercase;
}

.team__social__net {
  padding-bottom: 40px;
  padding-top: 20px;
  -webkit-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
  margin-bottom: -83px;
}
.team__social__net li {
  margin: 0 15px;
  display: inline-block;
}
.team__social__net li a:hover i {
  color: #d50c0d;
}
.team__social__net li a i {
  color: #959595;
  font-size: 15px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.team:hover .team__content {
  -webkit-transform: translateY(-80px);
  transform: translateY(-80px);
}

.team--2 .team .team__content .team__info h4 a {
  color: #ffffff;
}

.team--2 .team .team__content .team__info h6 {
  color: #ffffff;
}

.team--2 .team .team__content p {
  color: #ffffff;
}

.team--2 .team__social__net li a i {
  background: #fff none repeat scroll 0 0;
  color: #959595;
  font-size: 15px;
  height: 30px;
  line-height: 31px;
  width: 30px;
}

.team--2 .team__social__net li {
  display: inline-block;
  margin: 0 5px;
}

.team--2 .team__social__net li a:hover i {
  color: #d50c0d;
}

.team--2 .team__social__net {
  margin-bottom: -80px;
}

.team--2 .team {
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.team--2 .team .team__content {
  background: #221e1e none repeat scroll 0 0;
}

.team--2 .team .team__content .team__info::before {
  background: #fff;
}

.team--2 .team .team__content .team__info {
  margin-bottom: 13px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
  position: relative;
}

.team--2 .team__social__net {
  background: #221e1e none repeat scroll 0 0;
}

.team--2 .team .team__content p {
  padding: 0 39px;
}

.team--2 .team .team__content .team__info {
  margin-bottom: 13px;
  padding-bottom: 17px;
  padding-left: 20px;
  padding-right: 20px;
}

.team--2 .team .team__content {
  padding-left: 0;
  padding-right: 0;
}

/*------------------------
	Contact Area
--------------------------*/
.form-output p.error {
  color: #f10;
}

#googleMap {
  width: 100%;
  height: 440px;
}

.contact {
  background: #dd1012 none repeat scroll 0 0;
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  text-align: center;
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -moz-justify-content: center;
  justify-content: center;
  padding: 65px 0;
}
.contact:first-child {
  background: #d50c0d none repeat scroll 0 0;
}
.contact:last-child {
  background: #d50c0d none repeat scroll 0 0;
}
.contact .ct__icon {
  padding-right: 29px;
}
.contact .ct__icon i {
  color: #ffffff;
  font-size: 72px;
}
.contact .ct__address p {
  color: #ffffff;
  font-size: 36px;
  line-height: 45px;
  text-align: left;
}
.contact .ct__address p a {
  color: #ffffff;
  font-size: 36px;
  line-height: 45px;
}

.contact__form__wrap h2 {
  color: #666666;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 72px;
}

.contact-box input {
  border: 1px solid #eeeeee;
  color: #959595;
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  font-size: 14px;
  height: 55px;
  margin: 0 5px;
  padding: 0 20px;
}
.contact-box.name {
  margin-left: -5px;
  margin-right: -5px;
}
.contact-box.message textarea {
  border: 1px solid #eeeeee;
  color: #959595;
  font-size: 14px;
  margin-top: 30px;
  min-height: 250px;
  padding-left: 16px;
  padding-top: 20px;
  width: 100%;
  resize: none;
}

.contact-btn {
  margin-top: 50px;
}
.contact-btn .food__btn {
  border: 0 none;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 700;
  height: 50px;
  line-height: 50px;
  padding: 0 32px;
  text-transform: uppercase;
}

.contact__map__right .map-contacts > div#googlemap {
  height: 693px;
  width: 1046px;
}

.contact__map__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.map__thumb img {
  width: 100%;
}

@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .contact .ct__address p {
    font-size: 24px;
    line-height: 39px;
  }
  .contact .ct__address p a {
    font-size: 24px;
    line-height: 39px;
  }
  .contact .ct__icon i {
    font-size: 53px;
  }
  .contact__map__right .map-contacts > div#googlemap {
    height: 552px;
  }
  .contact__map__right .map-contacts > div#googlemap {
    width: 683px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .contact .ct__icon {
    padding-right: 10px;
  }
  .contact .ct__icon i {
    font-size: 41px;
    padding-top: 19px;
  }
  .contact .ct__address p {
    font-size: 20px;
    line-height: 37px;
  }
  .contact .ct__address p a {
    font-size: 24px;
    line-height: 38px;
  }
  .contact__map__wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .contact__map__right .map-contacts > div#googlemap {
    height: 492px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .contact__map__wrapper {
    display: block !important;
  }
  .contact {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .contact__map__right .map-contacts > div#googlemap {
    height: 500px;
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .contact {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .contact {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .contact .ct__address p {
    font-size: 18px;
    line-height: 32px;
  }
  .contact .ct__address p a {
    font-size: 20px;
    line-height: 41px;
  }
  .contact .ct__icon i {
    font-size: 63px;
  }
  .contact-box input {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .contact-box input + input {
    margin-top: 30px;
  }
  .map__thumb img {
    width: 100%;
  }
}
@media only screen and (max-width: 575px) {
  .contact__form__wrap h2 {
    font-size: 21px;
  }
}
/*=======  Our Bradcaump Area =======*/
.ht__bradcaump__wrap {
  height: 412px;
}
.ht__bradcaump__wrap .bradcaump__inner .bradcaump-title {
  color: #444444;
  font-family: "Open Sans", sans-serif;
  font-size: 36px;
  font-weight: 700;
  text-transform: uppercase;
}
.ht__bradcaump__wrap .bradcaump__inner .bradcaump-inner {
  margin-top: 11px;
}
.ht__bradcaump__wrap .bradcaump__inner .bradcaump-inner .breadcrumb-item {
  color: #444444;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.ht__bradcaump__wrap .bradcaump__inner .bradcaump-inner .brd-separetor i {
  color: #444444;
  font-size: 17px;
  padding: 0 7px;
}

.popupal__menu h4 {
  font-size: 29px;
  color: #444;
  font-family: "Open Sans", sans-serif;
  margin-top: 50px;
  text-transform: uppercase;
}

.ht__bradcaump__wrap
  .bradcaump__inner.brad__white
  .bradcaump-inner
  .brd-separetor
  i {
  color: #fff;
}

.ht__bradcaump__wrap
  .bradcaump__inner.brad__white
  .bradcaump-inner
  .breadcrumb-item {
  color: #fff;
}

.ht__bradcaump__wrap .bradcaump__inner.brad__white .bradcaump-title {
  color: #fff;
}

/*=======  Our MEnu Grid Area =======*/
.grid__show__item p {
  color: #7b7b7b;
  font-size: 14px;
}

.grid__show {
  border-bottom: 1px solid #e7e7e7;
  padding-bottom: 12px;
}

.menu__grid__item {
  background: #ffffff none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 60px;
}
.menu__grid__item .menu__grid__thumb {
  padding-top: 15px;
  position: relative;
}
.menu__grid__item .menu__grid__thumb a {
  display: block;
  overflow: hidden;
}
.menu__grid__item .menu__grid__thumb a img {
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.menu__grid__item .menu__grid__thumb .grid__item__offer {
  background: #d50c0d none repeat scroll 0 0;
  border: 5px solid #fff;
  border-radius: 100%;
  height: 70px;
  left: 50%;
  position: absolute;
  text-align: center;
  top: -30px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 70px;
}
.menu__grid__item .menu__grid__thumb .grid__item__offer span {
  color: #ffffff;
  display: block;
  font-size: 11px;
}
.menu__grid__item .menu__grid__inner {
  text-align: center;
}
.menu__grid__item .menu__grid__inner .menu__grid__details {
  padding: 30px 20px 31px;
}
.menu__grid__item .menu__grid__inner .menu__grid__details h2 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 20px;
  text-transform: capitalize;
}
.menu__grid__item .menu__grid__inner .menu__grid__details p {
  color: #959595;
  font-size: 14px;
  line-height: 24px;
}

.grid__item__offer span:first-child {
  padding-top: 9px;
}

.grid__item__offer span:last-child {
  margin-top: -7px;
}

.grid__prize__list {
  margin-bottom: 24px;
  margin-top: 16px;
}
.grid__prize__list li {
  display: inline-block;
  color: #d50c0d;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 700;
  margin: 0 8px;
}
.grid__prize__list li.old__prize {
  color: #dddddd;
}

.grid__addto__cart__btn {
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.grid__addto__cart__btn a {
  background: #d50c0d none repeat scroll 0 0;
  color: #ffffff;
  display: block;
  font-size: 18px;
  font-weight: 700;
  height: 50px;
  letter-spacing: 2px;
  line-height: 50px;
  text-transform: uppercase;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.grid__addto__cart__btn a:hover {
  background: #000;
}

.menu__grid__item:hover .grid__addto__cart__btn {
  height: 30px;
  opacity: 1;
  visibility: visible;
}

.grid__addto__cart__btn {
  height: 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease-in 0s;
  transition: all 0.5s ease-in 0s;
  visibility: hidden;
}

.menu__grid__item:hover .menu__grid__thumb a img {
  -webkit-transform: scale(1.15) rotate(3deg);
  transform: scale(1.15) rotate(3deg);
}

/*--------------------------
	Pagination Area
---------------------------*/
.food__pagination li a {
  color: #959595;
  font-size: 18px;
}

.food__pagination li {
  margin: 0 6px;
}

.food__pagination li a {
  background: transparent;
  border-radius: 100%;
  color: #959595;
  display: block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}

.food__pagination li.active a,
.food__pagination li a:hover {
  background: #444444 none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  display: block;
  height: 38px;
  line-height: 38px;
  text-align: center;
  width: 38px;
}

.food__pagination li.active a,
.food__pagination li a {
  position: relative;
  z-index: 1;
}

.food__pagination li a::before {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  bottom: 2px;
  content: "";
  left: 2px;
  position: absolute;
  right: 2px;
  top: 2px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
}

.food__pagination li a::after {
  background: #444444 none repeat scroll 0 0;
  border-radius: 100%;
  bottom: 4px;
  content: "";
  left: 4px;
  position: absolute;
  right: 4px;
  top: 4px;
  z-index: -1;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
}

.food__pagination li a:hover::before,
.food__pagination li.active a::before {
  opacity: 1;
}

.food__pagination li a:hover::after,
.food__pagination li.active a::after {
  opacity: 1;
}

/*=======  Our MEnu Grid Area =======*/
.single__food__list {
  margin-top: 40px;
}
.single__food__list .food__list__thumb {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  min-width: 470px;
}
.single__food__list .food__list__thumb a {
  display: block;
  position: relative;
}
.single__food__list .food__list__thumb a::before {
  background: #d50c0d none repeat scroll 0 0;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  top: 0;
  width: 80px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single__food__list .food__list__thumb a img {
  width: 100%;
}
.single__food__list .food__list__inner {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding-left: 40px;
}
.single__food__list .food__list__inner .food__list__details {
  -ms-flex-preferred-size: 75%;
  flex-basis: 75%;
}
.single__food__list .food__list__inner .food__list__details h2 {
  border-bottom: 1px dashed #6f6f6f;
  color: #333333;
  font-size: 26px;
  margin-bottom: 17px;
  padding-bottom: 22px;
  position: relative;
  text-transform: capitalize;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single__food__list .food__list__inner .food__list__details p {
  color: #959595;
  font-size: 14px;
  margin-bottom: 20px;
}
.single__food__list .food__list__inner .food__rating {
  -ms-flex-preferred-size: 20%;
  flex-basis: 20%;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  padding-top: 29px;
}
.single__food__list .food__list__inner .food__rating .list__food__prize span {
  color: #d50c0d;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-size: 50px;
  font-weight: 700;
  line-height: 45px;
  margin-bottom: 26px;
}

.single__food__list:hover .food__list__details h2 {
  border-bottom: 1px dashed #f10;
}

.single__food__list:hover .food__list__thumb a::before {
  width: 100%;
}

.food__nav {
  gap: 3px;
  border-bottom: 1px solid transparent;
  margin-left: -3px;
  margin-right: -3px;
}
.food__nav div {
  cursor: pointer;
  border: 1px dashed #dbdbdb;
  color: #333333;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-size: 18px;
  font-weight: 400;
  height: 40px;
  line-height: 40px;
  margin: 0 3px;
  padding: 0 24px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.food__nav div:hover {
  color: #d50c0d;
}

.food__nav div.active {
  color: #d50c0d;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single__food__list .food__list__thumb {
    -ms-flex-preferred-size: 48%;
    flex-basis: 48%;
  }
  .single__food__list .food__list__inner {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
    padding-left: 20px;
  }
  .single__food__list .food__list__inner .food__list__details h2 {
    font-size: 22px;
    margin-bottom: 12px;
    padding-bottom: 14px;
  }
  .single__food__list .food__list__inner .food__list__details p {
    font-size: 13px;
    margin-bottom: 11px;
  }
  .food__rating .rating li {
    display: inline-block;
    margin: 0;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single__food__list {
    display: block !important;
  }
  .single__food__list .food__list__thumb {
    display: block;
  }
  .single__food__list .food__list__inner {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .single__food__list {
    display: block !important;
  }
  .single__food__list .food__list__thumb {
    display: block;
  }
  .single__food__list .food__list__inner {
    padding-bottom: 20px;
    padding-top: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .single__food__list .food__list__inner {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: block !important;
    padding-left: 20px;
    padding-right: 20px;
  }
  .single__food__list .food__list__inner .food__list__details h2 {
    font-size: 22px;
  }
  .single__food__list .food__list__inner .food__rating {
    display: none;
  }
  .food__nav a {
    padding: 0 20px;
  }
}
/*=======  Our Gallery Area =======*/
.portfolio__menu button {
  background: transparent none repeat scroll 0 0;
  border: 0 none;
  color: #444444;
  font-size: 18px;
  font-weight: 700;
  margin: 0 16px;
  outline: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.portfolio__menu button:hover {
  color: #d50c0d;
}

.pro__item {
  margin-top: 30px;
}

.portfolio {
  position: relative;
}
.portfolio img {
  width: 100%;
}
.portfolio .portfolio__hover {
  height: 100%;
  left: 0;
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  z-index: 2;
}
.portfolio .portfolio__hover::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 100%;
  z-index: -1;
}
.portfolio .portfolio__hover .portfolio__action {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  -webkit-transition: 0.5s;
  transition: 0.5s;
  opacity: 0;
}

.portfolio__list li {
  display: inline-block;
  margin: 0 10px;
}
.portfolio__list li a {
  border: 1px solid #ffffff;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  font-size: 18px;
  height: 35px;
  line-height: 32px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  width: 40px;
}
.portfolio__list li a:hover {
  background: #d50c0d;
  border: 1px solid #d50c0d;
  color: #fff;
}
.portfolio:hover .portfolio__hover::before {
  opacity: 0.6;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.portfolio:hover .portfolio__action {
  opacity: 1;
}

/*=======  Our Blog List Area =======*/
.food__blog__list__wrapper {
  margin-top: -70px;
  padding: 0 200px;
}

.food__bl__list {
  margin-top: 70px;
}
.food__bl__list .fd__list__thumb a img {
  width: 100%;
}
.food__bl__list .fd__list__inner {
  background: #fafafa none repeat scroll 0 0;
  padding: 40px;
}
.food__bl__list .fd__list__inner .fd__list__details h2 {
  color: #262626;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  margin-bottom: 8px;
}
.food__bl__list .fd__list__inner .fd__list__details p {
  color: #666666;
  line-height: 25px;
  margin-top: 21px;
}

.list__meta li {
  color: #666666;
  font-size: 13px;
}
.list__meta li a {
  color: #666666;
  font-size: 13px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.list__meta li a:hover {
  color: #d50c0d;
}

.list__meta li + li {
  margin-left: 13px;
  padding-left: 13px;
  position: relative;
}

.list__meta li + li::before {
  background: #666666 none repeat scroll 0 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6px;
}

.blog-list-right-sidebar .food__bl__list .fd__list__inner {
  background: transparent none repeat scroll 0 0;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
}

.bl__list__btn {
  margin-top: 40px;
}
.bl__list__btn .food__btn.btn--transparent {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #9f9f9f;
  border-radius: 0;
  color: #191919;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  height: 42px;
  line-height: 40px;
  padding: 0 33px;
  text-transform: uppercase;
}
.bl__list__btn .food__btn.btn--transparent:hover {
  color: #fff;
  border: 1px solid #d50c0d;
}

.bl__list__btn .food__btn::before {
  background: #d50c0d none repeat scroll 0 0;
  border-radius: 0;
}

.blog-list-right-sidebar .food__bl__list .fd__list__inner .fd__list__details p {
  color: #333333;
  line-height: 25px;
  margin-top: 21px;
  line-height: 30px;
  padding-right: 8%;
}

.blog-list-right-sidebar .title__with__icon {
  padding-left: 60px;
  position: relative;
}

.blog-list-right-sidebar .title__with__icon::before {
  background: #313131 none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  content: "\e69b";
  font-family: Pe-icon-7-stroke;
  font-size: 20px;
  height: 40px;
  left: 0;
  line-height: 39px;
  position: absolute;
  text-align: center;
  width: 40px;
}

.blog-list-right-sidebar
  .food__bl__list
  .fd__list__inner
  .fd__list__details
  h2 {
  margin-bottom: 16px;
}

.fd__list__thumb {
  position: relative;
}

.img__with__icon {
  left: 40px;
  position: absolute;
  top: 40px;
}

.img__with__icon span {
  background: #313131 none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.blog__activation {
  position: relative;
}

.blog__activation .owl-nav div {
  left: 40px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.owl-theme .owl-nav {
  margin-top: 0;
}

.blog__activation .owl-nav div.owl-next {
  left: auto;
  right: 40px;
}

.blog__activation.owl-theme .owl-nav [class*="owl-"] {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  height: 50px;
  line-height: 61px;
  margin: 0;
  padding: 0;
  width: 50px;
}

.blog__activation .owl-nav div i {
  color: #010101;
  font-size: 32px;
}

.fd__list__thumb.bl__list__iframe {
  height: 434px;
}

.fd__list__thumb.bl__list__iframe iframe {
  height: 100%;
  width: 100%;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-list-right-sidebar
    .food__bl__list
    .fd__list__inner
    .fd__list__details
    h2 {
    font-size: 25px;
  }
  .food__blog__list__wrapper {
    padding: 0 135px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .food__blog__list__wrapper {
    padding: 0 80px;
  }
}
@media only screen and (max-width: 767px) {
  .food__blog__list__wrapper {
    padding: 0;
  }
}
@media only screen and (max-width: 575px) {
  .blog-list-right-sidebar
    .food__bl__list
    .fd__list__inner
    .fd__list__details
    h2 {
    font-size: 20px;
    line-height: 31px;
  }
  .food__bl__list .fd__list__inner {
    padding: 40px 10px;
  }
  .food__bl__list .list__meta {
    display: block !important;
  }
  .list__meta li + li {
    margin-left: 0;
    padding-left: 0;
  }
  .list__meta li + li::before {
    display: none;
  }
  .blog__activation .owl-nav div {
    left: 0;
  }
  .blog__activation .owl-nav div.owl-next {
    right: 0px;
  }
  .food__blog__list__wrapper {
    padding: 0;
  }
}
/*=======  Our Blog Mesonry Area =======*/
.bl__item {
  margin-top: 40px;
}

.blog__masonery__area {
  background: #f6f8fd none repeat scroll 0 0;
}

.blog__masonry {
  background: #fff none repeat scroll 0 0;
}
.blog__masonry .bl__maso__thumb {
  overflow: hidden;
}
.blog__masonry .bl__maso__thumb a img {
  width: 100%;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}
.blog__masonry .bl__mass__content {
  padding: 30px;
}
.blog__masonry .bl__mass__content h2 {
  line-height: 35px;
}
.blog__masonry .bl__mass__content h2 a {
  color: #262626;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  margin-top: 4px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.blog__masonry .bl__mass__content h2 a:hover {
  color: #d50c0d;
}
.blog__masonry .bl__mass__content p {
  color: #262626;
  font-size: 14px;
  line-height: 26px;
  margin-top: 18px;
}
.blog__masonry .bl__mass__content ul.bl__maso__meta li {
  color: #969696;
  display: inline-block;
  font-size: 13px;
}
.blog__masonry .bl__mass__content ul.bl__maso__meta li.meta__light {
  color: #262626;
  font-size: 14px;
  padding-right: 25px;
}

.blog__masonry:hover .bl__maso__thumb a img {
  -webkit-transform: scale(1.15) rotate(3deg);
  transform: scale(1.15) rotate(3deg);
}

.blog__masonery__wrap {
  margin-top: -30px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog__masonry .bl__mass__content h2 a {
    font-size: 20px;
  }
}
@media only screen and (max-width: 575px) {
  .blog__masonry .bl__mass__content h2 a {
    font-size: 19px;
  }
}
/*======= About Us =======*/
.food__container {
  border-bottom: 1px solid #eeeeee;
  border-left: 1px solid #eeeeee;
  border-top: 1px solid #eeeeee;
  margin-right: -30px;
  padding: 92px 43px 92px 40px;
}
.food__container .food__inner {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 23px;
}
.food__container .food__inner h2 {
  color: #333333;
  font-size: 26px;
}
.food__container .food__inner p {
  color: #959595;
  font-size: 14px;
  font-weight: 700;
  margin-top: 14px;
}
.food__container .food__details {
  margin-bottom: 22px;
  margin-top: 25px;
}
.food__container .food__details p {
  font-size: 14px;
}

.food__tab .food__nav {
  margin: 0;
}
.food__tab .food__nav a {
  border: 0 none;
  color: #333333;
  display: block;
  font-family: Open Sans;
  font-size: 14px;
  height: auto;
  line-height: 34px;
  margin: 0;
  padding: 0;
}

.about__video__inner {
  border-bottom: 1px solid #eaeaeb;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.about__video__inner .about__video__thumb {
  position: relative;
  z-index: 2;
}
.about__video__inner .about__video__thumb::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  opacity: 0.3;
  position: absolute;
  width: 100%;
}
.about__video__inner .about__video__thumb img {
  width: 100% !important;
}
.about__video__inner .about__video__thumb a.video-play-button {
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.about__video__inner .about__video__thumb a.video-play-button img {
  width: auto !important;
}
.about__video__inner .about__video__content {
  background: #fff none repeat scroll 0 0;
  border-left: 1px solid #eaeaeb;
  border-right: 1px solid #eaeaeb;
  height: 52px;
  line-height: 52px;
  text-align: center;
}
.about__video__inner .about__video__content span {
  -moz-border-bottom-colors: none;
  -moz-border-left-colors: none;
  -moz-border-right-colors: none;
  -moz-border-top-colors: none;
  border-color: #dddddd #dddddd currentcolor;
  -o-border-image: none;
  border-image: none;
  border-style: solid solid none;
  border-width: 1px 1px 0;
  color: #333333;
  cursor: pointer;
  display: inline-block;
  font-size: 18px;
  font-weight: 700;
  padding: 0 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .food__container {
    padding: 31px 34px 28px 23px;
  }
  .food__container .food__inner h2 {
    font-size: 23px;
  }
}
@media only screen and (max-width: 575px) {
  .food__container {
    padding: 50px 30px 50px 30px;
  }
}
.about__video__activation.owl-carousel .owl-item img {
  margin: auto;
  width: auto;
}

.about__video__activation.owl-carousel .owl-item img {
  display: inline-block;
}

.about__video__activation {
  position: relative;
}

.about__video__activation .owl-nav div {
  bottom: -3px;
  left: 25px;
  position: absolute;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.about__video__activation.owl-theme .owl-nav {
  margin-top: 0;
}

.about__video__activation .owl-nav div i {
  color: #959595;
  font-size: 23px;
}

.about__video__activation .owl-nav div i:hover {
  color: #d50c0d;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.about__video__activation .owl-nav div.owl-next {
  left: auto;
  right: 25px;
}

.about__video__activation.owl-theme .owl-nav [class*="owl-"] {
  background: transparent none repeat scroll 0 0;
  border-radius: 0;
  color: #000;
  cursor: pointer;
  display: inline-block;
  font-size: 14px;
  margin: 0;
  padding: 0;
}

.about__video__activation.owl-theme .owl-nav [class*="owl-"]:hover {
  background: transparent none repeat scroll 0 0;
}

.about__thumb img {
  width: 100%;
}

/*======= Accordion Area =======*/
.acc-header h5 a {
  border-bottom: 1px solid #eeeeee;
  color: #333333;
  display: block;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
  height: auto;
  padding: 26px 75px 27px 30px;
  position: relative;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.food_accordion .card {
  border: 1px solid #eeeeee;
  border-radius: 0;
}

.food_accordion .card-body {
  color: #666666;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  font-size: 18px;
  line-height: 32px;
  padding: 30px 30px 30px 40px;
  position: relative;
  z-index: 2;
  border-bottom: 3px solid #60ba62;
}

.food_accordion .card-body::before {
  background-image: url("images/shape/2.jpg");
  background-position: center bottom;
  background-repeat: no-repeat;
  bottom: 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
  background-size: cover;
}

.food_accordion .card + .card {
  margin-top: 50px;
}

.acc-header h5 a::after {
  content: "\f19c";
  font-family: Material-Design-Iconic-Font;
  position: absolute;
  right: 0;
  width: 75px;
  text-align: center;
  color: #c0c0c0;
  font-size: 24px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.acc-header h5 a::before {
  background: #eee none repeat scroll 0 0;
  content: "";
  height: 100%;
  position: absolute;
  right: 75px;
  top: 0;
  width: 1px;
}

.acc-header h5 a.collapsed::after {
  color: #333;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .acc-header h5 a {
    font-size: 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .acc-header h5 a {
    font-size: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .acc-header h5 a {
    font-size: 14px;
    line-height: 26px;
  }
}
/*======= Accordion Area =======*/
.side__bl__details {
  background: #fafafa none repeat scroll 0 0;
  padding: 30px;
}

.side__bl__thumb img {
  width: 100%;
}

.side__bl__details p {
  color: #333333;
  font-size: 14px;
  padding-right: 27px;
}

.side__bl__details h4 {
  color: #2f2f2f;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 600;
  margin-top: 17px;
  text-transform: capitalize;
}

.side__bl__details h6 {
  color: #2f2f2f;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

.bl__social__service {
  margin-top: 14px;
}

.bl__social__service li + li {
  margin-left: 30px;
}

.bl__social__service li a i {
  color: #a0a0a0;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.bl__social__service li a:hover i {
  color: #d50c0d;
}

.side__title {
  border-bottom: 2px solid #f1f1f1;
  color: #232323;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 39px;
  padding-bottom: 22px;
  position: relative;
  text-transform: capitalize;
}

.side__title::before {
  background: #6d6d6d none repeat scroll 0 0;
  bottom: -2px;
  content: "";
  height: 2px;
  position: absolute;
  width: 50px;
}

.serch__box {
  position: relative;
}

.serch__box input {
  border: 1px solid #e4e4e4;
  color: #b5b5b5;
  font-size: 14px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
  padding: 0 50px 0 20px;
  width: 100%;
}

.serch__box a {
  color: #b5b5b5;
  font-size: 16px;
  position: absolute;
  right: 30px;
  top: 46%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.serch__box a:hover {
  color: #d50c0d;
}

.recent__post__details {
  padding-left: 16px;
}

.recent__post__details span {
  color: #232323;
  font-size: 12px;
  font-weight: 600;
}

.recent__post__details h4 {
  color: #646464;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
}

.recent__post__details h4 a {
  color: #646464;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.recent__post__details h4 a:hover {
  color: #d50c0d;
}

.single__recent__post {
  margin-top: 30px;
}

.food__category li a span {
  float: right;
}

.food__category li a {
  color: #646464;
  font-size: 15px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.food__category li {
  padding-bottom: 11px;
  padding-top: 11px;
}

.food__category li + li {
  border-top: 1px solid #e5e5e5;
}

.food__category li:first-child {
  padding-top: 0;
}

.food__category li:last-child {
  padding-bottom: 0;
}

.food__category li a:hover {
  color: #d50c0d;
}

.single__comment h2 a {
  color: #262626;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 28px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.single__comment h2 a:hover {
  color: #d50c0d;
}

.single__comment h2 i {
  color: #969696;
  font-size: 20px;
  padding-right: 13px;
  line-height: 27px;
}

.single__comment h2 {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}

.comment__meta {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 6px;
  padding-left: 30px;
}
.comment__meta li {
  color: #b7b7b7;
  font-size: 12px;
}
.comment__meta li a {
  color: #b7b7b7;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
  padding-left: 16px;
  position: relative;
}
.comment__meta li a::before {
  background: #b7b7b7 none repeat scroll 0 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6px;
}

.food__comment .single__comment + .single__comment {
  margin-top: 26px;
}

.sidebar__contact {
  position: relative;
}

.sidebar__thumb img {
  width: 100%;
}

.sidebar__details {
  left: 13px;
  position: absolute;
  top: 50px;
}

.sidebar__details h4 {
  color: #fc892c;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.sidebar__details h2 {
  color: #f1486e;
  font-size: 36px;
  text-transform: uppercase;
}

.sidebar__details span {
  color: #ff3300;
  display: inline-block;
  font-family: "Alegreya", serif;
  font-weight: 700;
  margin-top: 4px;
}

.sidebar__con__btn {
  bottom: 18px;
  position: absolute;
  right: 70px;
}

.sidebar__con__btn a {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
}

.sidebar__con__btn a i {
  padding-left: 8px;
}

.sidebar__inbox {
  position: relative;
}

.sidebar__inbox input {
  border: 1px solid #e4e4e4;
  color: #aaaaaa;
  font-size: 14px;
  height: 50px;
  padding-left: 22px;
  width: 100%;
}

.sidebar__inbox a {
  background: #2c2c2c none repeat scroll 0 0;
  color: #fff;
  height: 40px;
  line-height: 40px;
  position: absolute;
  right: 6px;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 40px;
}

.sidebar__inbox a i {
  color: #fff;
  font-size: 16px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.sidebar__inbox a:hover {
  background: #d50c0d;
}

.instagram__list {
  margin-left: -2px;
  margin-right: -2px;
}
.instagram__list li {
  margin: 0 2px 4px;
}

.recent__twitter h6 a {
  color: #262626;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.recent__twitter h6 a i {
  color: #262626;
  font-size: 20px;
  padding-right: 13px;
}

.recent__twitter h6 {
  margin-bottom: 8px;
}

.recent__twitter p {
  color: #262626;
  font-size: 14px;
  font-weight: 700;
}

.twit__meta {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 7px;
}

.twit__meta li {
  color: #b7b7b7;
  display: inline-block;
  font-size: 12px;
}

.twit__meta li + li {
  margin-left: 10px;
  padding-left: 16px;
  position: relative;
}

.twit__meta li + li::before {
  background: #b7b7b7 none repeat scroll 0 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 6px;
}

.twitter__wrap .recent__twitter + .recent__twitter {
  margin-top: 21px;
}

.recent__twitter h6 a:hover {
  color: #d50c0d;
}

.var__social__net {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.var__social__net li a i {
  background: #3c5998 none repeat scroll 0 0;
  color: #ffffff;
  font-size: 20px;
  height: 60px;
  line-height: 60px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 50px;
}
.var__social__net li a i.fa-vimeo {
  background: #1da1f2 none repeat scroll 0 0;
}
.var__social__net li a i.fa-facebook {
  background: #bd081c none repeat scroll 0 0;
}
.var__social__net li a i.fa-instagram {
  background: #23b6ea none repeat scroll 0 0;
}

.var__social__net li + li {
  margin-left: 4px;
}

.tag__list {
  margin-left: -10px;
  margin-right: -10px;
}
.tag__list li {
  margin: 0 10px 20px;
}
.tag__list li a {
  background: transparent none repeat scroll 0 0;
  border: 1px solid #cbcbcb;
  color: #232323;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  height: 36px;
  line-height: 36px;
  padding: 0 30px;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.tag__list li a:hover {
  background: #d50c0d;
  border: 1px solid #d50c0d;
  color: #fff;
}

.food__sidebar {
  padding-right: 20px;
}

.food__category li {
  padding-left: 25px;
  position: relative;
}
.food__category li::before {
  background: #646464 none repeat scroll 0 0;
  content: "";
  height: 7px;
  left: 0;
  position: absolute;
  top: 42%;
  -webkit-transform: rotate(-136deg);
  transform: rotate(-136deg);
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 7px;
}
.food__category li:hover::before {
  background: #d50c0d;
}

.food__category li:last-child::before {
  top: 60%;
}

.food__category li:first-child::before {
  top: 28%;
}

/*=======  Our Blog Grid Area =======*/
.grid__big__inner {
  padding-top: 37px;
}
.grid__big__inner span {
  color: #b7b7b7;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
}
.grid__big__inner h2 {
  color: #383838;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  margin-top: 9px;
}
.grid__big__inner p {
  color: #262626;
  font-size: 16px;
  line-height: 30px;
  padding-right: 10%;
}

.bg__bl__meta {
  margin-top: 14px;
}
.bg__bl__meta li {
  color: #b7b7b7;
  font-size: 12px;
}
.bg__bl__meta li a {
  color: #b7b7b7;
  font-size: 12px;
  margin-left: 15px;
  padding-left: 16px;
  position: relative;
}
.bg__bl__meta li a::before {
  content: "-";
  left: 0;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.bg__bl__btn {
  margin-top: 33px;
}
.bg__bl__btn a.food__btn {
  border-radius: 0;
  color: #191919;
  font-family: "Open Sans", sans-serif;
  height: 40px;
  line-height: 40px;
  padding: 0 40px;
  text-transform: uppercase;
}
.bg__bl__btn a.food__btn::before {
  border-radius: 0;
}

.mt__-40 {
  margin-top: -40px;
}

.single__blog__grid {
  margin-top: 40px;
}
.single__blog__grid .grid__thumb a img {
  width: 100%;
}
.single__blog__grid .grid__inner {
  padding-top: 33px;
}
.single__blog__grid .grid__inner span {
  color: #b7b7b7;
  font-size: 12px;
  font-weight: 600;
}
.single__blog__grid .grid__inner h2 a {
  color: #262626;
  display: inline-block;
  font-family: "Open Sans", sans-serif;
  font-size: 24px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.single__blog__grid .grid__inner h2 a:hover {
  color: #d50c0d;
}
.single__blog__grid .grid__inner p {
  color: #262626;
  font-size: 16px;
  line-height: 30px;
  margin-top: 8px;
}

.grid__big__thumb a img,
.grid__thumb a img {
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.grid__big__thumb,
.grid__thumb {
  overflow: hidden;
}

.grid__big__product:hover .grid__big__thumb a img,
.single__blog__grid:hover .grid__thumb a img {
  -webkit-transform: scale(1.2) rotate(3deg);
  transform: scale(1.2) rotate(3deg);
}

.video__post__title {
  color: #232323;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 40px;
  padding-bottom: 20px;
  position: relative;
  text-transform: capitalize;
}
.video__post__title::before {
  background: #6d6d6d none repeat scroll 0 0;
  bottom: 0;
  content: "";
  height: 2px;
  position: absolute;
  width: 50px;
}

.video__post .video__post__thumb {
  padding-right: 30px;
  position: relative;
  -ms-flex-preferred-size: 104%;
  flex-basis: 104%;
}
.video__post .video__post__thumb a.video-play-button {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
}
.video__post .video__post__thumb a img {
  max-width: 100%;
}
.video__post .video__post__details span {
  color: #b7b7b7;
  font-size: 12px;
  font-weight: 600;
}
.video__post .video__post__details h2 {
  line-height: 26px;
  margin-bottom: 23px;
  margin-top: 7px;
}
.video__post .video__post__details h2 a {
  color: #262626;
  font-family: Open Sans;
  font-size: 24px;
  font-weight: 600;
}
.video__post .video__post__details p {
  color: #262626;
  line-height: 30px;
}
.video__post .video__post__details ul.bg__bl__meta {
  margin-bottom: 13px;
}

.video__post__details p + p {
  margin-top: 8px;
}

.small__vedio__post__wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-top: 40px;
}
.small__vedio__post__wrap .single__small__video__post {
  -ms-flex-preferred-size: 50%;
  flex-basis: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__thumb {
  min-width: 120px;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__thumb a {
  display: block;
  position: relative;
}
.small__vedio__post__wrap
  .single__small__video__post
  .sm__post__thumb
  a::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.small__vedio__post__wrap
  .single__small__video__post
  .sm__post__thumb
  a::after {
  background: #fff none repeat scroll 0 0;
  border-radius: 100%;
  color: #d40c0d;
  content: "\f04b";
  font-family: FontAwesome;
  height: 30px;
  left: 50%;
  line-height: 30px;
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%) translateX(-50%);
  transform: translateY(-50%) translateX(-50%);
  width: 30px;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__thumb a img {
  width: 100%;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__details {
  padding-left: 18px;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__details h6 a {
  color: #646464;
  display: inline-block;
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  margin-bottom: 3px;
}
.small__vedio__post__wrap .single__small__video__post .sm__post__details span {
  color: #232323;
  font-size: 12px;
}

.small__vedio__post__wrap .single__small__video__post:nth-child(n + 3) {
  margin-top: 40px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bg__bl__meta li a {
    margin-left: 12px;
    padding-left: 11px;
  }
  .single__blog__grid .grid__inner p {
    font-size: 14px;
    line-height: 26px;
  }
  .video__post .video__post__thumb {
    padding-right: 23px;
  }
  .video__post .video__post__details h2 a {
    font-size: 22px;
  }
  .video__post__details .bg__bl__meta li a::before {
    display: none;
  }
  .video__post .video__post__details p {
    font-size: 14px;
    line-height: 27px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .sidebar__con__btn {
    bottom: 43px;
    right: 178px;
  }
}
@media only screen and (max-width: 575px) {
  .bg__bl__meta li a {
    margin-left: 12px;
    padding-left: 14px;
  }
  .video__post .video__post__thumb {
    padding-right: 0;
  }
}
.blog-list-right-sidebar .food__blog__list__wrapper {
  margin-top: -70px;
  padding: 0;
}

.blog-list-right-sidebar .food__sidebar {
  padding-left: 20px;
  padding-right: 0;
}

/*======= Blog Details =======*/
.blog-details-page .food__sidebar {
  padding-left: 40px;
  padding-right: 0;
}

.bl__dtl__thumb img {
  width: 100%;
}

.bl__dtl__postmeta {
  border-bottom: 1px dashed #cecece;
  margin-bottom: 26px;
  padding-bottom: 30px;
  padding-top: 30px;
}
.bl__dtl__postmeta .bl__meta li {
  color: #959595;
  font-size: 14px;
}
.bl__dtl__postmeta .bl__meta li i {
  color: #959595;
  font-size: 14px;
  padding-right: 10px;
}
.bl__dtl__postmeta .bl__meta li a {
  color: #666666;
  font-weight: 700;
}
.bl__dtl__postmeta .bl__like__comment li {
  border: 1px solid #e6e6e6;
  border-radius: 5px;
  height: 35px;
  line-height: 32px;
  text-align: center;
  width: 70px;
}
.bl__dtl__postmeta .bl__like__comment li a i {
  display: inline-block;
  height: 35px;
  margin-right: 4px;
  text-align: center;
  width: 25px;
}

.bl__meta li + li {
  padding-left: 34px;
}

.bl__like__comment li + li {
  margin-left: 10px;
}

.bl__details__content span {
  color: #959595;
  font-weight: 700;
}
.bl__details__content h2 {
  color: #333333;
  font-family: "Open Sans", sans-serif;
  font-size: 30px;
  margin-bottom: 21px;
}
.bl__details__content p {
  color: #959595;
  font-size: 16px;
  line-height: 28px;
}
.bl__details__content blockquote {
  font-size: 16px;
  font-style: italic;
  font-weight: 300;
  margin: 30px 0;
  padding-left: 27px;
  padding-right: 10%;
  position: relative;
  line-height: 28px;
}
.bl__details__content blockquote::before {
  background: #d50c0d none repeat scroll 0 0;
  content: "";
  height: 65px;
  left: 0;
  position: absolute;
  top: 0;
  width: 8px;
}

.food__sm__blog li + li {
  padding-left: 20px;
}

.food__sm__blog li:first-child {
  -ms-flex-preferred-size: 45.5%;
  flex-basis: 45.5%;
}

.food__sm__blog li:last-child {
  -ms-flex-preferred-size: 55%;
  flex-basis: 55%;
}

.food__sm__blog li a img {
  width: 100%;
}

.food__sm__blog {
  margin-bottom: 17px;
  margin-top: 20px;
}

.rel__blog__list {
  margin-bottom: 22px;
  margin-top: 30px;
}

.sm__bl__list li {
  padding-top: 15px;
  padding-left: 25px;
  position: relative;
}
.sm__bl__list li::before {
  background: #646464 none repeat scroll 0 0;
  content: "";
  height: 6px;
  left: 0;
  position: absolute;
  top: 58%;
  -webkit-transform: rotate(-135deg);
  transform: rotate(-135deg);
  width: 6px;
}
.sm__bl__list li a {
  color: #959595;
  font-size: 16px;
}

.bl__share {
  margin-top: 33px;
}
.bl__share span {
  color: #959595;
  font-size: 16px;
  font-weight: 400;
  padding-right: 10px;
}

.blog__social__net li + li {
  margin-left: 10px;
}

.blog__social__net li a:hover i {
  background: #d50c0d;
  border: 1px solid #d50c0d;
  color: #fff;
}
.blog__social__net li a i {
  border: 1px solid #ededed;
  color: #959595;
  display: inline-block;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 30px;
}

.bl__details__content {
  padding-bottom: 65px;
}

.blog__title {
  color: #3a3a3a;
  font-family: "Open Sans", sans-serif;
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.food__author {
  border: 1px solid #f1f1f1;
  border-radius: 5px;
  padding: 30px 20px;
  margin-bottom: 50px;
}
.food__author .author__thumb {
  padding-right: 30px;
}
.food__author .author__details h2 {
  font-family: Open Sans;
  font-size: 20px;
  padding-bottom: 8px;
  text-transform: capitalize;
}
.food__author .author__details .author__meta {
  border-bottom: 1px solid #f1f1f1;
  margin-bottom: 17px;
  padding-bottom: 10px;
}
.food__author .author__details .author__meta span {
  color: #959595;
  font-size: 13px;
}
.food__author .author__details p {
  color: #959595;
  line-height: 30px;
  margin-bottom: 13px;
}

.author__link li + li {
  margin-left: 10px;
}

.author__link li a i {
  background: #eeeeee none repeat scroll 0 0;
  color: #666666;
  display: inline-block;
  font-size: 15px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  width: 30px;
}

.author__link li a:hover i {
  background: #d50c0d none repeat scroll 0 0;
  color: #fff;
}

.blog__comment__inner .commnet__thumb {
  padding-right: 30px;
}
.blog__comment__inner .comment__details h5 {
  color: #333333;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 7px;
  text-transform: capitalize;
}
.blog__comment__inner .comment__details p {
  line-height: 30px;
  margin-top: 12px;
}

.cmment__date span {
  color: #959595;
  font-size: 13px;
}

.cmment__date {
  border-bottom: 1px dashed #f4f4f4;
  padding-bottom: 7px;
}

.reply__btn li a i {
  color: #b5b5b5;
  font-size: 16px;
}

.reply__btn li + li {
  margin-left: 10px;
}

.reply__btn li a i.fa.fa-angle-up,
.reply__btn li a i.fa.fa-angle-down {
  border: 1px dashed #dddddd;
  border-radius: 5px;
  height: 20px;
  line-height: 16px;
  text-align: center;
  width: 20px;
}

.comment.comment__reply {
  margin-bottom: 66px;
  margin-left: 98px;
  margin-top: 66px;
}

.comment__form .ct__form__box.d-flex {
  margin-left: -15px;
  margin-right: -15px;
}
.comment__form .ct__form__box input {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  color: #232323;
  height: 40px;
  margin: 0 15px;
  padding: 0 20px;
  width: 100%;
}
.comment__form .ct__form__box textarea {
  border: 1px solid #eeeeee;
  color: #232323;
  min-height: 150px;
  padding: 20px;
  resize: none;
  width: 100%;
}

.blog__comment__form {
  margin-top: 52px;
}

.ct__form__box + .ct__form__box {
  margin-top: 30px;
}

.comment__btn {
  margin-top: 30px;
}

.ct__form__box input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #232323;
  opacity: 1;
}

.ct__form__box input:-ms-input-placeholder {
  /* IE 10+ */
  color: #232323;
  opacity: 1;
}

.ct__form__box input::-moz-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box input::-ms-input-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box input::placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box textarea::-webkit-input-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box textarea::-moz-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box textarea:-ms-input-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box textarea::-ms-input-placeholder {
  color: #232323;
  opacity: 1;
}

.ct__form__box textarea::placeholder {
  color: #232323;
  opacity: 1;
}

.comment__btn .food__btn {
  font-size: 18px;
  font-weight: 400;
  height: 45px;
  line-height: 45px;
  padding: 0 32px;
}

.blog__lst__post__wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -10px;
  margin-right: -10px;
}
.blog__lst__post__wrapper .latest__post__inner {
  -ms-flex-preferred-size: 33.33%;
  flex-basis: 33.33%;
  margin: 0 10px;
  position: relative;
}
.blog__lst__post__wrapper .latest__post__inner .latest__post__thumb a {
  display: block;
  position: relative;
  z-index: 1;
}
.blog__lst__post__wrapper .latest__post__inner .latest__post__thumb a::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.blog__lst__post__wrapper .latest__post__inner .latest__post__thumb a img {
  width: 100%;
}
.blog__lst__post__wrapper .latest__post__inner .latest__inner {
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
  z-index: 2;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.blog__lst__post__wrapper .latest__post__inner .latest__inner h6 a {
  color: #fff;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
}

.latest__post__inner .post__hover__inner {
  height: 100%;
  opacity: 0;
  position: absolute;
  text-align: center;
  top: 0;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  width: 100%;
  z-index: 2;
}
.latest__post__inner .post__hover__inner::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.8;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}
.latest__post__inner .post__hover__inner .post__hover__action {
  position: absolute;
  text-align: center;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 100%;
}
.latest__post__inner .post__hover__inner .post__hover__action h6 a {
  color: #ffffff;
  display: block;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 6px;
  text-transform: capitalize;
}
.latest__post__inner .post__hover__inner .post__hover__action span {
  color: #ffffff;
  font-size: 14px;
}
.latest__post__inner .post__hover__inner .post__hover__action p {
  color: #ffffff;
  font-size: 14px;
  line-height: 24px;
  margin-top: 15px;
  padding: 0 14%;
}
.latest__post__inner .post__hover__inner .post__hover__action .bl__btn {
  margin-top: 24px;
}
.latest__post__inner:hover .post__hover__inner {
  opacity: 1;
}

.blog__lst__post__wrapper .latest__post__inner:hover .latest__inner {
  opacity: 0;
}

.blog__lst__post__wrapper
  .latest__post__inner:hover
  .latest__post__thumb
  a::before {
  opacity: 0;
}

.blog-details-page .sidebar__con__btn {
  bottom: 13px;
  right: 52px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .blog-details-page .food__sidebar {
    padding-left: 0;
  }
}
@media only screen and (max-width: 767px) {
  .blog-details-page .sidebar__con__btn {
    bottom: 45px;
    right: 167px;
  }
  .blog-details-page .food__sidebar {
    padding-left: 0;
  }
  .food__sm__blog li:last-child {
    -ms-flex-preferred-size: 57%;
    flex-basis: 57%;
  }
}
@media only screen and (max-width: 575px) {
  .food__sm__blog li:last-child {
    -ms-flex-preferred-size: 60%;
    flex-basis: 60%;
  }
  .bl__details__content blockquote::before {
    height: 167px;
  }
  .rel__blog__list {
    display: block !important;
  }
  .sm__blog__thumb {
    margin-top: 20px;
  }
  .food__author .author__thumb {
    padding-right: 0;
  }
  .author__details {
    margin-top: 30px;
  }
  .food__author .author__details .author__meta span {
    font-size: 11px;
  }
  .blog__lst__post__wrapper .latest__post__inner {
    -ms-flex-preferred-size: 43%;
    flex-basis: 43%;
  }
  .blog__lst__post__wrapper {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .latest__post__inner:nth-child(3) {
    margin-top: 30px;
  }
  .latest__post__inner .post__hover__inner .post__hover__action p {
    display: none;
  }
  .latest__post__inner
    .post__hover__inner
    .post__hover__action
    .bl__btn
    .food__btn {
    font-size: 15px;
    height: 41px;
    line-height: 39px;
    padding: 0 10px;
  }
  .blog__comment__inner .commnet__thumb {
    padding-right: 0;
    margin-bottom: 30px;
  }
  .comment.comment__reply {
    margin-left: 0;
  }
  .comment__form .ct__form__box input + input {
    margin-top: 30px;
  }
  .blog-details-page .food__sidebar {
    padding-left: 0;
  }
  .recent__post__details h4 {
    font-size: 13px;
    line-height: 23px;
  }
}
/*======= Cart Styles =======*/
/*---------------------------------------
  26. Our Cart Area
----------------------------------------*/
.car-header-title h2 {
  font-size: 20px;
  margin: 0;
  text-transform: uppercase;
}

.table-content table {
  background: #fff none repeat scroll 0 0;
  border-color: #eaeaea;
  border-radius: 0;
  border-style: solid;
  border-width: 1px 0 0 1px;
  text-align: center;
  width: 100%;
}

.table-content table th {
  border-top: medium none;
  font-weight: bold;
  padding: 20px 10px;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  white-space: nowrap;
}

.table-content table th,
.table-content table td {
  border-bottom: 1px solid #eaeaea;
  border-right: 1px solid #eaeaea;
}

.table-content table td {
  border-top: medium none;
  font-size: 13px;
  padding: 20px 10px;
  text-align: center;
  vertical-align: middle;
}

.table-content table td input {
  background: #e5e5e5 none repeat scroll 0 0;
  border: medium none;
  border-radius: 3px;
  color: #6f6f6f;
  font-size: 15px;
  font-weight: normal;
  height: 40px;
  padding: 0 5px 0 10px;
  width: 60px;
}

.table-content table td.product-subtotal {
  font-size: 14px;
  font-weight: bold;
  width: 120px;
  color: #777;
}

.table-content table td.product-name a {
  font-size: 14px;
  font-weight: 700;
  margin-left: 10px;
  color: #6f6f6f;
}

.table-content table td.product-name {
  width: 270px;
}

.table-content table td.product-thumbnail {
  width: 130px;
}

.table-content table td.product-remove i {
  color: #919191;
  display: inline-block;
  font-size: 20px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  width: 40px;
}

.table-content table .product-price .amount {
  font-size: 15px;
  font-weight: 700;
  color: #777;
}

.table-content table td.product-remove i:hover {
  color: #252525;
}

.table-content table td.product-quantity {
  width: 180px;
}

.table-content table td.product-remove {
  width: 150px;
}

.table-content table td.product-price {
  width: 130px;
}

.table-content table td.product-name a:hover {
  color: #d50c0d;
}

.cart_totals table tr.order-total th,
.cart_totals table tr.order-total .amount {
  font-size: 20px;
  text-transform: uppercase;
  white-space: nowrap;
}

.owl-theme .owl-controls .owl-page span {
  background: #ffffff none repeat scroll 0 0;
  border: 1px solid #c1c1c1;
  border-radius: 100%;
  display: block;
  height: 13px;
  margin: 3px;
  width: 13px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.owl-theme .owl-controls .owl-page.active span,
.owl-theme .owl-controls.clickable .owl-page:hover span {
  border: 2px solid #c1c1c1;
}

.product-thumbnail a img {
  width: 100%;
}

.table-content table .title-top th {
  background: #f6f6f6 none repeat scroll 0 0;
  border-bottom: 1px solid transparent;
  border-right: 1px solid transparent;
}

.cartbox__btn {
  background: #f6f6f6 none repeat scroll 0 0;
  border-bottom: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 40px;
}

.cart__btn__list li a {
  background: #fff none repeat scroll 0 0;
  border-radius: 5px;
  display: block;
  font-family: Alegreya;
  font-size: 18px;
  font-weight: 400;
  height: 50px;
  line-height: 50px;
  padding: 0 26px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}

.cart__btn__list li a:hover {
  background: #60ba62 none repeat scroll 0 0;
  color: #fff;
}

.cartbox-total {
  background: #f6f6f6 none repeat scroll 0 0;
  padding: 0 40px;
}

.cartbox__total__area {
  margin-top: 90px;
}

.cart__total__amount {
  background: #d50c0d none repeat scroll 0 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 60px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  line-height: 60px;
  padding: 0 40px;
}

.cart__total__list li {
  color: #444444;
  font-family: Alegreya;
  font-size: 18px;
  height: 60px;
  line-height: 60px;
}

.cart__total__tk li {
  color: #444444;
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  line-height: 60px;
}

.cart__total__amount span {
  color: #fff;
  font-size: 18px;
}

/*======= Blog Details =======*/
.food__menu__inner .food__menu__details {
  padding-left: 40px;
}
.food__menu__inner .food__menu__details .food__menu__content h2 {
  color: #3a3a3a;
  font-family: Open Sans;
  font-size: 26px;
  margin-bottom: 10px;
  text-transform: uppercase;
}
.food__menu__inner .food__menu__details .food__menu__content p {
  color: #959595;
  font-size: 14px;
  line-height: 30px;
  margin-top: 23px;
}

.food__dtl__prize {
  margin-bottom: 14px;
}
.food__dtl__prize li {
  color: #d50c0d;
  font-size: 24px;
  font-weight: 700;
}
.food__dtl__prize li.old__prize {
  font-weight: 300;
}

.food__dtl__prize li + li {
  margin-left: 20px;
}

.food__menu__content .rating {
  border-bottom: 1px dashed #e8e0d9;
  padding-bottom: 14px;
}

.cart-plus-minus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
}
.cart-plus-minus {
  border: 1px dashed #ebebeb;
  color: #d50c0d;
  font-size: 18px;
  font-weight: 700;
  height: 45px;
  width: 40px;
  background: white;
}

.dec.qtybutton {
  border: 1px dashed #e7e7e7;
  color: #d50c0d;
  cursor: pointer;
  font-size: 24px;
  height: 45px;
  line-height: 40px;
  text-align: center;
  width: 40px;
  user-select: none;
  background: white;
}

.inc.qtybutton {
  border: 1px dashed #ebebeb;
  color: #d50c0d;
  cursor: pointer;
  font-size: 24px;
  height: 45px;
  line-height: 45px;
  right: 61%;
  text-align: center;
  width: 40px;
  user-select: none;
  background: white;
}

.add__to__cart__btn {
  left: 120px;
  position: relative;
}

.prodict-statas span {
  color: #959595;
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-top: 18px;
}

.add__to__cart__btn a.food__btn {
  color: #ffffff;
  font-size: 18px;
  padding: 0 22px;
}

.menu__descrive__area {
  margin-top: 73px;
}

.menu__nav {
  border-bottom: 0 none;
  margin-bottom: 70px;
}
.menu__nav a {
  border: 1px solid #959595;
  color: #959595;
  font-family: "Alegreya", serif;
  font-size: 18px;
  height: 45px;
  line-height: 45px;
  padding: 0 22px;
  text-transform: capitalize;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
}
.menu__nav a.active,
.menu__nav a:hover {
  background: #d50c0d none repeat scroll 0 0;
  border: 1px solid #d50c0d;
  color: #fff;
}

.single__dec__content p {
  color: #959595;
  line-height: 30px;
}
.single__dec__content p strong {
  color: #666666;
  font-weight: 700;
}

.single__dec__content p + p {
  margin-top: 20px;
}

.single__dec__content:not(.active) {
  display: none;
}

.single__review {
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 20px;
}
.single__review .review__thumb {
  -ms-flex-preferred-size: 23%;
  flex-basis: 23%;
}
.single__review .review__thumb img {
  width: 100%;
}
.single__review .review__details {
  padding-left: 30px;
  -ms-flex-preferred-size: 77%;
  flex-basis: 77%;
}
.single__review .review__details h3 {
  color: #333333;
  font-family: Open Sans;
  font-size: 20px;
  font-weight: 700;
  text-transform: capitalize;
}
.single__review .review__details p {
  color: #959595;
  line-height: 30px;
}
.single__review .review__details .rev__meta {
  border-bottom: 1px dashed #f3f3f3;
  padding-bottom: 14px;
}

.single__review + .single__review {
  margin-top: 40px;
}

.beef_product {
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 52px;
  padding-top: 15px;
  position: relative;
  text-align: center;
}
.beef_product .beef__thumb img {
  width: 100%;
}
.beef_product .beef__hover__info {
  position: absolute;
  text-align: center;
  top: -32px;
  width: 100%;
}
.beef_product .beef__hover__info .beef__hover__inner {
  background: #d50c0d none repeat scroll 0 0;
  border: 4px solid #fff;
  border-radius: 100%;
  color: #fff;
  height: 70px;
  margin: auto;
  width: 70px;
}
.beef_product .beef__hover__info .beef__hover__inner span {
  display: block;
  line-height: 46px;
}
.beef_product .beef__details {
  padding-top: 20px;
}
.beef_product .beef__details h4 {
  font-family: Open Sans;
  font-size: 20px;
  text-transform: capitalize;
}
.beef_product .beef__details p {
  font-size: 14px;
  line-height: 24px;
}

.beef__hover__inner span + span {
  margin-top: -27px;
}

.beef__prize {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 13px;
  margin-top: 14px;
}
.beef__prize li {
  color: #d50c0d;
  font-size: 24px;
  font-weight: 700;
}
.beef__prize li.old__prize {
  color: #dddddd;
  text-decoration: line-through;
}

.beef__prize li + li {
  margin-left: 16px;
}

.beef__cart__btn {
  margin-top: 24px;
}
.beef__cart__btn a {
  background: #fff none repeat scroll 0 0;
  border-top: 1px solid #f8f8f8;
  color: #959595;
  display: block;
  font-size: 18px;
  height: 50px;
  line-height: 50px;
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
}

.beef_product:hover .beef__cart__btn a {
  background: #d50c0d none repeat scroll 0 0;
  color: #fff;
}

/*======= Login Styles =======*/
.wrapper {
  position: relative;
}

.accountbox-wrapper {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: fixed;
  overflow: hidden;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  opacity: 1;
  z-index: 1000;
}
.accountbox-wrapper .body-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #15181c;
  -webkit-transition: all 0.5s ease-in-out 0s;
  transition: all 0.5s ease-in-out 0s;
  cursor: url(images/icon/close.png), crosshair;
  opacity: 0.85;
  visibility: visible;
  z-index: 21;
}
.accountbox__filters {
  padding-bottom: 7px;
}
.accountbox__filters li {
  padding: 0 10px;
  margin-bottom: 0;
  position: relative;
}
.accountbox__filters li:first-child {
  padding-left: 0;
}
.accountbox__filters li:last-child {
  padding-right: 0;
}
.accountbox__filters li:last-child:before {
  display: none;
}
.accountbox__filters li:before {
  content: "";
  height: 3px;
  width: 10px;
  background: #222;
  position: absolute;
  right: 0;
  margin-right: -5px;
  top: 50%;
}
.accountbox__filters li a {
  font-size: 24px;
  font-weight: 700;
  color: #222;
}
.accountbox__filters li a.active {
  color: #d50c0d;
}
@keyframes accountAnimation {
  from {
    transform: translate(-50%, -120%);
  }
  to {
    transform: translate(-50%, -50%);
  }
}
@keyframes accountAnimationSmall {
  from {
    transform: translate(-50%, -120%);
  }
  to {
    transform: translate(-50%, 0);
  }
}
.accountbox {
  background: #e3e3e3;
  position: absolute;
  z-index: 23;
  left: 50%;
  top: 50%;
  width: 460px;
  padding: 70px;
  padding-right: 120px;
  animation: accountAnimation 0.5s cubic-bezier(0.38, 0.7, 0.66, 0.82);
  animation-fill-mode: forwards;
}
.accountbox .single-input {
  margin-top: 25px;
}
.accountbox .single-input input {
  height: 40px;
  line-height: 42px;
  border: 1px solid #aaaaaa;
  background: transparent;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  width: 100%;
  padding: 0 30px;
}
.accountbox .single-input input:focus {
  border-color: #d50c0d;
}
.accountbox .single-input input::-webkit-input-placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input input::-moz-placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input input:-ms-input-placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input input::-ms-input-placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input input::placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input input::-moz-placeholder {
  color: #333 !important;
  opacity: 1;
}
.accountbox .single-input button {
  padding: 0 35px;
  border: 0 none;
}
.cr-round--lg {
  border-radius: 100px;
}
.cr-round--lg input {
  border-radius: 100px;
}

.social-icons ul {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
}
.social-icons ul li a i {
  border: 1px solid #6a6a6a;
  border-radius: 100%;
  color: #6a6a6a;
  display: block;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.social-icons ul li a:hover i {
  color: #d50c0d;
}

.social-icons ul li + li {
  margin-left: 16px;
}

.accountbox-login__others {
  margin-top: 50px;
}
.accountbox-login__others h6 {
  color: #898989;
  font-size: 14px;
  margin-bottom: 25px;
}

.accountbox-close-button {
  display: inline-block;
  height: 40px;
  width: 40px;
  background: transparent;
  text-align: center;
  line-height: 40px;
  font-size: 25px;
  color: #333333;
  position: absolute;
  right: 4px;
  top: 6px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.accountbox-close-button:hover {
  opacity: 0.9;
  color: #d50c0d;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .accountbox-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .accountbox-wrapper .accountbox {
    top: 50px;
  }
  .accountbox-wrapper .accountbox .accountbox-close-button {
    left: auto;
    right: 0;
  }
  .accountbox-wrapper .accountbox {
    animation: accountAnimationSmall 0.5s cubic-bezier(0.38, 0.7, 0.66, 0.82);
    animation-fill-mode: forwards;
  }
}
@media only screen and (max-width: 767px) {
  .accountbox-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .accountbox-wrapper .accountbox {
    top: 50px;
    width: calc(100% - 60px);
    left: 50%;
  }
  .accountbox-wrapper .accountbox.is-visible {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .accountbox-wrapper .accountbox {
    animation: accountAnimationSmall 0.5s cubic-bezier(0.38, 0.7, 0.66, 0.82);
    animation-fill-mode: forwards;
  }
}
@media only screen and (max-width: 575px) {
  .accountbox-wrapper {
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .accountbox-wrapper .accountbox {
    padding: 45px 30px;
    padding-right: 30px;
    top: 50px;
    width: calc(100% - 60px);
    left: 50%;
  }
  .accountbox-wrapper .accountbox.is-visible {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  .accountbox-wrapper .accountbox {
    animation: accountAnimationSmall 0.5s cubic-bezier(0.38, 0.7, 0.66, 0.82);
    animation-fill-mode: forwards;
  }
}
/*======= Cartbox Styles =======*/
.cartbox-wrap {
  position: absolute;
  height: 100vh;
  width: 100%;
  left: 0;
  top: 0;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: all 0.7s ease-in-out 0s;
  transition: all 0.7s ease-in-out 0s;
  z-index: 99;
}
.cartbox-wrap .body-overlay {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(30, 30, 30, 0.85);
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  cursor: url(images/icon/close.png), crosshair;
}
.cartbox-wrap.is-visible {
  visibility: visible;
  opacity: 1;
}
.cartbox-wrap.is-visible .body-overlay {
  visibility: visible;
  opacity: 1;
}
.cartbox-wrap.is-visible .cartbox {
  right: 0;
  -webkit-animation: slideInRight 0.7s ease-in-out both;
  animation: slideInRight 0.7s ease-in-out both;
}

.cartbox {
  position: fixed;
  top: 0;
  right: -100%;
  z-index: 9992;
  height: 100%;
  width: 465px;
  background: #fafafa;
  min-height: 100vh;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -ms-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  padding: 25px 35px;
  -webkit-transition: all 0.7s ease-in-out 0s;
  transition: all 0.7s ease-in-out 0s;
  overflow-y: auto;
}
.cartbox .cartbox-close {
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  background: transparent;
  font-size: 25px;
  text-transform: uppercase;
  margin-right: -5px;
  border: 0 none;
}
.cartbox .cartbox-close:hover {
  color: #d50c0d;
}

.cartbox__items {
  padding-bottom: 20px;
  border-bottom: 1px solid #aaaaaa;
}

.cartbox__item {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -moz-align-items: center;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #f0f0f0;
}
.cartbox__item:last-child {
  border-bottom: none;
}

.cartbox__item__thumb {
  width: 70px;
  display: inline-block;
}

.cartbox__item__thumb a {
  display: inline-block;
}

.cartbox__item__content {
  -webkit-box-flex: 100;
  -ms-flex-positive: 100;
  flex-grow: 100;
  padding-left: 25px;
  padding-right: 25px;
}
.cartbox__item__content h5 {
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  line-height: 1;
}
.cartbox__item__content h5 a:hover {
  color: #d50c0d;
}
.cartbox__item__content p {
  font-size: 12px;
  margin-bottom: 0;
}
.cartbox__item__content span.price {
  font-weight: 500;
  color: #d50c0d;
}

button.cartbox__item__remove {
  background: none;
  font-size: 20px;
  padding: 0;
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
  -webkit-transform-origin: center center;
  transform-origin: center center;
  color: #999;
  border: 0 none;
}
button.cartbox__item__remove:hover {
  color: #d50c0d;
}

.cartbox__total {
  margin-top: 20px;
}
.cartbox__total ul {
  padding-left: 0;
  list-style: none;
}
.cartbox__total ul li {
  display: -webkit-box;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -moz-justify-content: space-between;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  font-style: italic;
  line-height: 1.5;
}
.cartbox__total ul li.shipping-charge span.price:before {
  content: "+";
  font-size: 16px;
  padding-right: 3px;
}
.cartbox__total ul li.grandtotal {
  font-size: 24px;
  color: black;
  margin-top: 7px;
  font-weight: 700;
}

.cartbox__buttons {
  margin-top: 30px;
}
.cartbox__buttons a.cr-btn {
  text-align: center;
  margin-top: 20px;
  display: block;
  height: 50px;
  padding: 4px 30px;
  border-radius: 0;
}
.cartbox__buttons a.cr-btn:before {
  border-radius: 0;
}

.cartbox__item__thumb a img {
  max-width: 100%;
}

.cartbox__buttons a {
  display: block;
  font-family: Open Sans;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.cartbox__buttons a + a {
  margin-top: 20px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cartbox {
    width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cartbox {
    width: 350px;
    padding: 15px 25px;
  }
}
@media only screen and (max-width: 767px) {
  .cartbox {
    width: 350px;
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .cartbox {
    width: calc(100% - 30px);
    padding: 15px 15px;
  }
}
@media only screen and (max-width: 575px) {
  .brand__list li + li {
    margin-top: 30px;
  }
  .brand__list li a img {
    width: auto;
  }
  .brand__list {
    display: block !important;
    text-align: center;
  }
}
/*======= Checkout Styles =======*/
.single-accordion {
  margin-bottom: 30px;
}

.mb--20 {
  margin-bottom: 20px;
}

input[type="submit"] {
  cursor: pointer;
}

.single-accordion {
  margin-bottom: 30px;
}

.order-details .food__btn {
  border: 0 none;
}

.accordion-head {
  background-color: #d50c0d;
  color: #ffffff;
  display: block;
  font-size: 15px;
  font-weight: 700;
  height: 47px;
  line-height: 49px;
  padding: 0 12px 0 28px;
  position: relative;
  text-align: left;
  text-transform: uppercase;
  width: 100%;
  font-family: "Open Sans", sans-serif;
}
.accordion-head:focus,
.accordion-head:hover {
  color: #ffffff;
}
.accordion-head.collapsed {
  background-color: #f1f2f3;
  color: #333;
}
.accordion-head.collapsed:focus {
  color: #333;
}
.accordion-head.collapsed:hover {
  color: #d50c0d;
}

.accordion-body {
  overflow: hidden;
  padding: 30px;
  border: 1px solid #eeeeee;
  border-top: none;
}

.checkout-method-list {
  float: left;
  margin-bottom: 15px;
  width: 100%;
  padding-left: 0;
  list-style: none;
}
.checkout-method-list li {
  display: block;
  text-transform: capitalize;
  cursor: pointer;
  float: left;
  margin-right: 30px;
}
.checkout-method-list li::before {
  content: "\f192";
  font-family: "Fontawesome";
  float: left;
  line-height: 24px;
  padding-left: 1px;
  margin-right: 10px;
  font-size: 16px;
}
.checkout-method-list li.active::before {
  color: #d50c0d;
}

.shipping-method h5 {
  font-size: 16px;
  text-transform: capitalize;
  margin-bottom: 15px;
  font-weight: 700;
  font-style: italic;
}
.shipping-method p span {
  font-weight: 700;
  color: #333;
  text-transform: capitalize;
  margin-right: 10px;
}

.payment-method-list {
  float: left;
  width: 100%;
  padding-left: 0;
  list-style: none;
}
.payment-method-list li {
  display: block;
  text-transform: capitalize;
  cursor: pointer;
  float: left;
  margin-right: 30px;
}
.payment-method-list li::before {
  content: "\f192";
  font-family: "Fontawesome";
  float: left;
  line-height: 24px;
  padding-left: 1px;
  margin-right: 10px;
  font-size: 16px;
}
.payment-method-list li.active::before {
  color: #d50c0d;
}

.shipping-form-toggle {
  display: inline-block;
  text-transform: capitalize;
  cursor: pointer;
  padding: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 20px;
}
.shipping-form-toggle::before {
  content: "\f192";
  font-family: "Fontawesome";
  float: left;
  line-height: 24px;
  padding-left: 1px;
  margin-right: 10px;
  font-size: 16px;
}
.shipping-form-toggle.active::before {
  color: #d50c0d;
}

.checkout-login-form {
  float: left;
  width: 100%;
}
.checkout-login-form .input-box input {
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}
.checkout-login-form .input-box input[type="submit"] {
  width: 120px;
  margin-right: 0;
  background-color: #d50c0d;
  padding: 8px 15px;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  border-radius: 50px;
}
.checkout-login-form .input-box input[type="submit"]:hover {
  background-color: #bd0b0c;
}

.checkout-register-form {
  display: none;
  float: left;
  width: 100%;
}
.checkout-register-form .input-box input {
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}
.checkout-register-form .input-box input[type="submit"] {
  width: 120px;
  margin-right: 0;
  background-color: #d50c0d;
  padding: 8px 15px;
  border: none;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 700;
  margin-bottom: 0;
  border-radius: 50px;
}
.checkout-register-form .input-box input[type="submit"]:hover {
  background-color: #bd0b0c;
}

.checkout-form {
  float: left;
  width: 100%;
}
.checkout-form.shipping-form {
  display: none;
}
.checkout-form input {
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  /*color: #959595;*/
  font-size: 15px;
  float: left;
}
.checkout-form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0) url("images/icons/select-arrow-down-2.png")
    no-repeat scroll right 5px center;
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}
.checkout-form select option {
  padding: 5px 15px;
}
.checkout-form textarea {
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 120px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}

.payment-form {
  float: left;
  width: 100%;
  display: none;
}
.payment-form label {
  display: block;
  font-size: 15px;
}
.payment-form input {
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}
.payment-form select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: rgba(0, 0, 0, 0) url("images/icons/select-arrow-down-2.png")
    no-repeat scroll right 5px center;
  width: 100%;
  border: 1px solid #ededed;
  background-color: #ffffff;
  height: 40px;
  line-height: 24px;
  padding: 7px 15px;
  color: #959595;
  font-size: 15px;
  float: left;
}
.payment-form select option {
  padding: 5px 15px;
}
.payment-form a {
  color: #d50c0d;
  font-size: 15px;
  margin-top: 5px;
}

.order-details-wrapper h2 {
  background: #d50c0d;
  color: #ffffff;
  display: block;
  font-size: 14px;
  font-weight: 700;
  height: 45px;
  line-height: 45px;
  padding: 0 12px 0 28px;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
}

.order-details {
  background-color: #f1f2f3;
  padding: 10px 0;
  text-align: left;
  float: left;
  width: 100%;
}
.order-details ul {
  padding-left: 0;
  list-style: none;
}
.order-details ul li {
  border-bottom: 1px solid #e9e9e9;
  display: block;
  float: left;
  padding: 16px 28px;
  text-align: left;
  width: 100%;
}
.order-details ul li:last-child {
  border-bottom: 0 solid transparent;
}
.order-details ul li p {
  color: #606060;
  display: block;
  margin: 0;
  width: 60%;
  float: left;
}
.order-details ul li p:last-child {
  width: 40%;
}
.order-details ul li p.strong {
  color: #303030;
  font-weight: 600;
  text-transform: uppercase;
}
.order-details ul li p input {
  display: block;
  float: left;
  margin-right: 10px;
  margin-top: 3px;
}
.order-details ul li p label {
  color: #606060;
  display: block;
  float: left;
  font-weight: 300;
  margin: 0;
  overflow: hidden;
  line-height: 20px;
}

/*======= About Us Styles =======*/
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .choose__img--1 {
    bottom: 0;
    right: -154px;
  }
  .choose__img--1 img {
    width: 69%;
  }
  .choose__img--2 {
    bottom: 1px;
    left: -64px;
  }
  .food__sideimg--1 {
    left: 11%;
  }
  .box__layout {
    margin-left: 150px;
    margin-right: 150px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .slide__pizza--3 {
    bottom: -17%;
    right: -132px;
  }
  .slide__pizza--3 img {
    width: 77%;
  }
  .slide__pizza--2 img {
    width: 73%;
  }
  .slide__pizza--2 {
    left: -22%;
    top: -59%;
  }
  .slider--three .slide .slider__content .slider__inner h1 {
    font-size: 130px;
    margin-bottom: 26px;
  }
  .box__layout .subs--4 .subscribe__inner.subscribe--3 {
    padding: 84px 10px 30px;
  }
  .box__layout .subscribe__inner.subscribe--3 .enter__email__address input {
    -ms-flex-preferred-size: 77.5%;
    flex-basis: 77.5%;
  }
  .box__layout {
    margin-left: 40px;
    margin-right: 40px;
  }
  .food__menu__inner .food__menu__details {
    padding-left: 20px;
  }
  .food__menu__inner .food__menu__details .food__menu__content h2 {
    font-size: 21px;
  }
  .add__to__cart__btn {
    left: 0;
    margin-top: 20px;
  }
  .food__menu__inner .food__menu__details .food__menu__content p {
    font-size: 13px;
  }
  .prodict-statas span {
    margin-top: 3px;
  }
  .food__menu__inner .food__menu__details .food__menu__content p {
    margin-top: 8px;
  }
  .small__vedio__post__wrap
    .single__small__video__post
    .sm__post__details
    h6
    a {
    font-size: 13px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    margin-top: 177px;
    padding: 35px 20px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .slide__pizza--3 {
    bottom: -17%;
    right: -132px;
  }
  .slide__pizza--3 img {
    width: 77%;
  }
  .slide__pizza--2 img {
    width: 73%;
  }
  .slide__pizza--2 {
    left: -22%;
    top: -59%;
  }
  .slider--three .slide .slider__content .slider__inner h1 {
    font-size: 130px;
    margin-bottom: 26px;
  }
  .slider--three .slide .slider__content .slider__inner h1 {
    font-size: 99px;
    margin-bottom: 26px;
    line-height: 124px;
  }
  .slider__inner h1 img {
    width: 89%;
  }
  .box__layout .subs--4 .fd__subscribe__area {
    padding: 0 20px;
  }
  .box__layout .subs--4 .subscribe__inner.subscribe--3 {
    padding: 84px 10px 30px;
  }
  .box__layout {
    margin-left: 20px;
    margin-right: 20px;
  }
  .fd__pizza__area.bg-image--25 {
    background: #f9f9f9 none repeat scroll 0 0;
    padding-bottom: 0px;
  }
  .order-now-area {
    background: #fac01c none repeat scroll 0 0;
    padding-bottom: 130px;
  }
  .fullscreen {
    min-height: auto !important;
    padding: 150px 0;
  }
  .single__tab__content + .single__tab__content {
    margin-top: 110px;
  }
  .slide .slider__content .slider__inner .slider__input .src__btn a {
    font-size: 14px;
    padding: 0 28px;
  }
  .slide .slider__content .slider__inner .slider__input input {
    width: 400px;
  }
  .about .about__details {
    padding: 25px 10px 36px;
  }
  .fd__about p {
    padding-right: 12%;
  }
  .testimonial-2::before {
    left: 2px;
    top: 16px;
  }
  .team--2 .team .team__content p {
    font-size: 13px;
    margin-bottom: 5px;
    padding: 0 15px;
  }
  .testimonial__activation--1 {
    margin-left: 0;
    margin-right: 0;
  }
  .testimonial-2 + .testimonial-2 {
    display: none;
  }
  .testimonial-2 {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .header__right {
    position: relative;
    z-index: 2;
  }
  .slide__pizza--2,
  .subscri__shape--2,
  .slide__pizza--3,
  .subscri__shape--1 {
    display: none;
  }
  .slider--three .slide .slider__content .slider__inner h1 {
    font-size: 130px;
    margin-bottom: 26px;
  }
  .slider--three .slide .slider__content .slider__inner h1 {
    font-size: 45px;
    line-height: 56px;
    margin-bottom: 26px;
  }
  .slider__fixed--height {
    height: auto;
    padding: 200px 0;
  }
  .testimonial-inner--3 .testimonial__inner {
    padding: 20px 20px 20px 30px;
  }
  .testimonial-inner--3 .testimonial__inner .testimonial__content p {
    font-size: 16px;
    line-height: 30px;
  }
  .slider__inner h1 img {
    width: 84%;
  }
  .box__layout {
    margin-left: 10px;
    margin-right: 10px;
  }
  .subs--4 .subscribe__inner.subscribe--3 {
    padding: 84px 0 30px;
  }
  .footer--4 .copyright .copyright__inner {
    padding: 0;
  }
  .footer--4 .copyright .copyright__inner .cpy__right--left p {
    font-size: 12px;
  }
  .food__menu__inner .food__menu__details {
    padding-left: 0;
  }
  /*.inc.qtybutton {*/
  /*  right: 52%;*/
  /*}*/
  .single__review .review__details p {
    font-size: 13px;
    line-height: 26px;
  }
  .small__vedio__post__wrap .single__small__video__post {
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
  }
  .small__vedio__post__wrap .single__small__video__post:nth-child(2) {
    margin-top: 40px;
  }
  .small__vedio__post__wrap
    .single__small__video__post
    .sm__post__details
    h6
    a {
    font-size: 13px;
    line-height: 21px;
  }
  .fd__pizza__area.bg-image--25 {
    background: #f9f9f9 none repeat scroll 0 0;
    padding-bottom: 0px;
  }
  .order-now-area {
    background: #fac01c none repeat scroll 0 0;
    padding-bottom: 130px;
  }
  .order__now__inner h2 {
    font-size: 29px;
  }
  .order__now__inner p {
    font-size: 17px;
    line-height: 26px;
    margin-top: 14px;
  }
  .order__now__inner h6 {
    margin-bottom: 16px;
  }
  .counter__up__inner {
    padding: 100px 0;
  }
  .single__resturent__food .resturent__details h4 {
    font-size: 22px;
    margin-top: 21px;
  }
  .cart__btn__list li a {
    padding: 0 20px;
  }
  .cart__btn__list li:nth-child(4) a {
    margin-top: 20px;
  }
  .htc__news__inner {
    display: block;
  }
  .subscribe__btn {
    margin-top: 20px;
  }
  .fullscreen {
    min-height: auto !important;
    padding: 100px 0;
  }
  .single__tab__content + .single__tab__content {
    margin-top: 50px;
  }
  .slider--two .slider__text__img img.layear-2 {
    margin-top: 10px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    margin-top: 41px;
  }
  .about .about__details h2 span {
    font-size: 25px;
  }
  .about .about__details {
    padding: 25px 15px 36px;
  }
  .counter__up__inner,
  .subs__address__content {
    display: block !important;
  }
  .funfact + .funfact {
    margin-top: 40px;
  }
  .fd__about .section__title .title__line {
    margin-bottom: 14px;
    margin-top: 7px;
  }
  .slider--three .slider__fixed--height {
    padding: 100px 0;
  }
  .feature .feature__details h6 {
    font-size: 14px;
  }
  .feature .feature__details p {
    margin-top: 13px;
    padding: 0 24px;
  }
  .pp__food__details p {
    font-size: 14px;
  }
  .pp__food__bottom {
    margin-top: 22px;
  }
  .pp__food__prize {
    left: -7px;
    top: -18px;
  }
  .food__stuff__inner h3 {
    font-size: 26px;
    line-height: 34px;
    margin-top: 10px;
  }
  .food__stuff__link li + li {
    margin-left: 0;
  }
  .blog .blog__details p {
    padding: 0;
  }
  .fanfact--2 .funfact .fact__title h2 {
    font-size: 22px;
  }
  .slider--four .slider__fixed--height {
    padding: 100px 0;
  }
  .slide .slider__content .slider__inner .slider__input input.res__search,
  .slide .slider__content .slider__inner .slider__input input {
    width: 300px;
  }
  .app__download__container .dwn__app__list li:first-child {
    margin-right: 0;
  }
  .app__download__container .app__download__inner,
  .app__download__container .dwn__app__list {
    text-align: left;
  }
  .slide .slider__content .slider__inner .slider__input input,
  .slide .slider__content .slider__inner .slider__input .src__btn a {
    height: 50px;
    line-height: 50px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input input {
    width: 300px;
  }
  .slider--two
    .slide
    .slider__content
    .slider__inner
    .slider__input
    input
    + input {
    margin-top: 23px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input .src__btn {
    margin-top: 20px;
  }
  .slider--two .slider__text__img img.layear-2 {
    margin-top: -17px;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input {
    margin-top: 31px;
  }
}
@media only screen and (min-width: 480px) and (max-width: 767px) {
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .food__menu__details {
    margin-top: 0;
    padding-left: 10px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__title__prize
    span.menu__prize {
    font-size: 30px;
    line-height: 25px;
    margin-left: 0px;
  }
  .special__food__menu
    .fd__tab__content
    .single__tab__panel
    .tab__content__wrap
    .single__tab__content
    .food__menu
    .food__menu__details
    .fd__menu__details {
    margin-top: 3px;
  }
  .htc__news__inner {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .subscribe__btn {
    margin-top: 0px;
  }
  .news__input {
    -ms-flex-preferred-size: 75%;
    flex-basis: 75%;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input input {
    width: 196px;
  }
  .slider--two
    .slide
    .slider__content
    .slider__inner
    .slider__input
    input
    + input {
    margin-top: 0;
  }
  .slider--two .slide .slider__content .slider__inner .slider__input input {
    font-size: 14px;
    padding: 0 15px;
  }
  .fanfact--2 .counter__up__inner,
  .brand__list {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }
  .fanfact--2 .funfact,
  .brand__list li {
    -ms-flex-preferred-size: 50%;
    flex-basis: 50%;
  }
  .fanfact--2 .funfact + .funfact,
  .brand__list li + li {
    margin-top: 0;
  }
  .fanfact--2 .funfact:nth-child(n + 3),
  .brand__list li:nth-child(n + 3) {
    margin-top: 30px;
  }
  .portfolio__menu button {
    margin: 0 16px 10px;
  }
}
@media only screen and (max-width: 575px) {
  .logo {
    width: 80%;
  }
  .mainmenu__wrap .container .row {
    -ms-flex-wrap: nowrap !important;
    flex-wrap: nowrap !important;
  }
  .copyright .copyright__inner .cpy__right--left p {
    font-size: 12px;
  }
  .subs--4 .subscribe__inner.subscribe--3 {
    padding: 0;
    padding-top: 40px;
  }
  .subs--4 .fd__subscribe__area {
    padding: 0;
  }
  .subscribe__inner.subscribe--3 h2 {
    font-size: 17px;
    line-height: 31px;
  }
  .slider--four .slide .slider__content .slider__inner h1 {
    margin-bottom: 12px;
  }
  .ht__bradcaump__wrap .bradcaump__inner .bradcaump-title {
    font-size: 25px;
  }
  .single__review {
    display: block !important;
  }
  .single__review .review__details {
    padding-left: 0;
    margin-top: 30px;
  }
  .single__review .review__details .rev__meta {
    display: block !important;
  }
  .cart__btn__list li + li {
    margin-top: 20px;
  }
  .cart__btn__list li:nth-child(4) a {
    margin-top: 0px;
  }
  .testimonial-2 + .testimonial-2 {
    display: none;
  }
  .testimonial-2 {
    border-radius: 0;
  }
  .testimonial-2::before {
    left: 4px;
    top: 14px;
  }
  .subscribe--3 .sign__up.food__btn {
    padding: 0 17px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
  .subs--4 .fd__subscribe__area {
    padding: 0;
  }
}
.nav-link.active {
  background-color: #ac0404 !important;
}

.page-link {
  color: #ac0404;
}

.page-item.active .page-link {
  background-color: #ac0404;
  border-color: #ac0404;
}

.page-item:not(.active) .page-link:hover {
  color: #ac0404;
}

.accountbox-trigger nav {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block !important;
}

.zmdi.active {
  color: #ac0404 !important;
}

.btn.btn-karkandak {
  color: white;
  background-color: #ac0404;
}

.cursor-pointer {
  cursor: pointer;
}
